'use strict';

import rolesTable from '../../custom/roles/table';

KTUtil.ready(() => {
    if (!$("#page-create-role").length) {
        return null;
    }

    const dualListBox = new DualListbox('.dual-listbox', {
        availableTitle: 'Disponibles',
        selectedTitle: 'Seleccionados',
        addButtonText: 'Agregar',
        removeButtonText: 'Eliminar',
        addAllButtonText: 'Agregar todos',
        removeAllButtonText: 'eliminar todos',
        options: [],
    });

    $('.dual-listbox__item').each((index, element) => {
        const name = $(element).data('id');
        const popover = $(`span[data-id='${name}']`);

        if (popover && popover.length) {
            const content = $(popover).data('content');

            $(element).popover({
                title: 'Ayuda',
                content: content,
                placement: 'bottom',
                trigger: 'hover'
            });
        }
    });
});