'use strict';

import { object } from 'underscore';
import deleteClientModal from './modal-delete';

// Private functions and attributes
const inputSearch = $('#clients-table--search');
const selectFilterField = $('#clients-table--field-filter');
const selectLocation = $('#clients-table--location');
const columnSwitcher = ('.column_switcher');

const init = (options, resolveModal) => {
    // We MUST get table element this way (we can't create global variable)
    const table = $('#clients-table');

    if (!table || !table.length) {
        return;
    }

    const columns = [{
        field: 'first_name',
        title: 'Nombre',
        sortable: true,
        autoHide: false,
        filterable: false
    }, {
        field: 'last_name',
        title: 'Apellido',
        sortable: true,
        autoHide: false,
        filterable: false
    }, {
        field: 'email',
        title: 'Email',
        sortable: true,
        autoHide: false,
        filterable: false
    }, {
        field: 'location_id',
        title: 'Localidad',
        sortable: false,
        autoHide: false,
        filterable: false,
        template: (row) => {
            return row.location.name
        }
    }, {
        field: 'phone',
        title: 'Teléfono',
        sortable: true,
        autoHide: false,
        filterable: false,
        template: (row) => {
            if (row?.phone && row?.alternative_phone) {
                return `${row?.phone} / ${row?.alternative_phone}`
            }

            if (row?.phone) {
                return row?.phone;
            }

            if (row?.alternative_phone) {
                return row?.alternative_phone
            }
        }
    }];

    // NOTE: We use `withSelector` to search clients in the repair creation page
    if (options?.withSelector) {
        columns.unshift({
            field: 'id',
            title: '#',
            sortable: true,
            autoHide: false,
            textAlign: 'center',
            selector: {
                class: 'kt-checkbox--solid'
            }
        });
    // NOTE: We must show `actions` button just when we are in the clients page
    } else if (!options?.query) {
        columns.unshift({
            field: 'id',
            title: '#',
            sortable: true,
            autoHide: false,
            textAlign: 'center',
            template: (row, index, datatable) => {
                const dropup = (datatable.getPageSize() - index) <= 4 ? 'dropup' : '';
                const options = [];
                let html = '';

                if (permissions.includes('UPDATE_CLIENTS')) {
                    options.push({
                        id: row.id,
                        class: 'client_edit_btn',
                        icon: 'la la-edit',
                        text: 'Editar'
                    });
                }

                if (permissions.includes('DELETE_CLIENTS')) {
                    options.push({
                        divider: true,
                    });

                    options.push({
                        id: row.id,
                        class: 'client_delete_btn',
                        icon: 'la la-trash',
                        text: 'Eliminar'
                    });
                }

                if (!options.length) {
                    return row.id;
                }

                html = `
                    <div class="dropdown dropdown-inline ${dropup}">
                        <a href="#" class="btn btn-text-dark btn-hover-light font-weight-bold" data-toggle="dropdown">
                            <i class="flaticon-more-v2"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right actions">
                            ${
                                options.map((option) => {
                                    if (option.divider) {
                                        return '<div class="dropdown-divider"></div>';
                                    }

                                    return `
                                        <button data-id="${option.id}" class="dropdown-item ${option.class}">
                                            <i class="${option.icon} mr-2"></i> ${option.text}
                                         </button>
                                    `;
                                }).join('')
                            }
                        </div>
                    </div>

                    ${row.id}
                `;

                return html;
            }
        });
    } else {
        columns.unshift({
            field: 'id',
            title: '#',
            sortable: true,
            autoHide: false,
            textAlign: 'center',
        });
    }

    const datatable = table.KTDatatable({
        // datasource definition
        data: {
            type: 'remote',
            source: {
                read: {
                    method: 'GET',
                    url: route('admin.clients.all').toString(),
                    params: {
                        query: options?.query
                    }
                },
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            saveState: true,
        },

        // layout definition
        layout: {
            customScrollbar: false,
            scroll: true,
            // height: 400,
            footer: false,
            header: true,
            icons: {
                rowDetail: null
            }
        },

        // column sorting
        sortable: true,

        pagination: true,

        search: {
            onEnter: true,
            input: inputSearch,
        },

        // columns definition
        columns,
    });

    /**
     * Table filter events
     */
    $(selectLocation).on('change', (e) => {
        const newData = $(e.target).select2('data')[0];
        const oldData = datatable.stateGet('location-filter');

        console.log(newData, oldData)
        
        if (newData?.id == oldData?.id) {
            return;
        }

        if (newData) {
            datatable.stateKeep('location-filter', {
                id: newData?.id,
                text: newData?.text
            });
        }

        datatable.search(e.currentTarget.value, 'location_id');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectFilterField).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'filter_by');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(columnSwitcher).change((e) => {
        const field = e.currentTarget.name;
        const active = e.currentTarget.checked;

        if (!datatable.getColumnByField(field)) {
            return;
        }

        datatable.columns(field).visible(active);
        datatable.stateKeep('columns', datatable.options.columns);
    });

    /**
     * Datatable events
     */
    datatable.on('datatable-on-init', () => {
        const tableMeta = datatable.stateGet('meta');
        const locationFilter = datatable.stateGet('location-filter');
        const tableColumns = datatable.stateGet('columns');

        if (locationFilter && selectLocation?.length) {
            const newOption = new Option(locationFilter.text, locationFilter.id, false, false);
            $(selectLocation).append(newOption).trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.filter_by) {
            $(selectFilterField).val(tableMeta.query.filter_by);
        }

        if (tableMeta && tableMeta.query && tableMeta.query.general_search) {
            $(inputSearch).val(tableMeta.query.general_search);
        }

        if (!tableColumns) {
            return null;
        }

        // Update table column visibility switchers
        $.each(tableColumns, function(index, obj) {
            const isVisible = obj.visible === undefined || obj.visible === true;

            datatable.columns(obj.field).visible(isVisible);
            datatable.stateKeep('columns', datatable.options.columns);
            $(`input[name="${obj.field}"]`).prop("checked", isVisible);
        });
    });

    datatable.on('datatable-on-ajax-fail', (jqXHR) => {
        let text = 'No se pudo realizar la operación. Contacte con el equipo técnico';

        if (jqXHR && jqXHR.status) {
            text += `. Código de error: ${jqXHR.status}`
        }

        swal.fire({
            title: 'Oops... Algo salió mal 😢',
            text,
            icon: 'error'
        });
    });

    datatable.on('datatable-on-check', (event, args) => {
        const firstSelection = args[0];

        if (resolveModal && typeof resolveModal === 'function') {
            resolveModal(firstSelection);
        }
    });

    /**
     * Table action events
     */
    $(document.body).on('click', 'button.client_edit_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.clients.edit', {id: target.data('id')}).toString();

        window.open(url, '_self');
    });

    $(document.body).on('click', 'button.client_delete_btn', (e) => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const options = {
            id: target.data('id'),
        };

        KTApp.block(table, {
            message: 'Cargando...'
        });

        deleteClientModal.init(options)
            .then((response) => {
                if (response) {
                    datatable.reload();
                }

                KTApp.unblock(table);
            })
            .catch(() => {
                datatable.reload();

                KTApp.unblock(table);
            });
    });
};

const destroy = () => {
    // We MUST get table element this way (we can't create global variable)
    $('#clients-table').KTDatatable('destroy');
};

export default {
    init,
    destroy
}
