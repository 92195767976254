'use strict';

import repairsTable from '../../custom/repairs/table';

KTUtil.ready(() => {
    if (!$("#page-all-repairs").length) {
        return null;
    }

    $('.product_selector').select2({
        placeholder: 'Sin filtrar',
        minimumInputLength: 0,
        containerCssClass: 'select2-selection__placeholder--custom',
        ajax: {
            url: (data) => {
                if (!data.term) {
                    return route('admin.products.all', { popular: true }).toString();
                }

                return route('admin.products.all').toString();
            },
            dataType: 'json',
            delay: 400, // bigger delay than the rest because minimumInputLength < 1
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                // NOTE: This is to be able to remove the filter. We can not use null values with select2 but Laravel convert empty strings to null
                results.unshift({
                    id: ' ',
                    text: 'Sin filtrar',
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    repairsTable.init();
});
