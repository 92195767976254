(function ($) {
  // see: https://summernote.org/deep-dive/#callbacks
  $.extend($.summernote.options.callbacks, {
    onPaste: function (e) {
      e.preventDefault();

      const bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
      document.execCommand('insertText', false, bufferText);
    }
  });

  $.extend($.summernote.lang, {
    'es-ES': {
      font: {
        bold: 'Negrita',
        italic: 'Cursiva',
        underline: 'Subrayado',
        clear: 'Eliminar estilo de letra',
        height: 'Altura de línea',
        name: 'Tipo de letra',
        strikethrough: 'Tachado',
        subscript: 'Subíndice',
        superscript: 'Superíndice',
        size: 'Tamaño de la fuente',
        sizeunit: 'Unidad del tamaño de letra',
      },
      image: {
        image: 'Imagen',
        insert: 'Insertar imagen',
        resizeFull: 'Redimensionar a tamaño completo',
        resizeHalf: 'Redimensionar a la mitad',
        resizeQuarter: 'Redimensionar a un cuarto',
        resizeNone: 'Tamaño original',
        floatLeft: 'Flotar a la izquierda',
        floatRight: 'Flotar a la derecha',
        floatNone: 'No flotar',
        shapeRounded: 'Forma: Redondeado',
        shapeCircle: 'Forma: Círculo',
        shapeThumbnail: 'Forma: Miniatura',
        shapeNone: 'Forma: Ninguna',
        dragImageHere: 'Arrastre una imagen o texto aquí',
        dropImage: 'Suelte una imagen o texto',
        selectFromFiles: 'Seleccione un fichero',
        maximumFileSize: 'Tamaño máximo del fichero',
        maximumFileSizeError: 'Superado el tamaño máximo de fichero.',
        url: 'URL de la imagen',
        remove: 'Eliminar la imagen',
        original: 'Original',
      },
      video: {
        video: 'Vídeo',
        videoLink: 'Enlace del vídeo',
        insert: 'Insertar un vídeo',
        url: 'URL del vídeo',
        providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion o Youku)',
      },
      link: {
        link: 'Enlace',
        insert: 'Insertar un enlace',
        unlink: 'Quitar el enlace',
        edit: 'Editar',
        textToDisplay: 'Texto a mostrar',
        url: '¿A qué URL lleva este enlace?',
        openInNewWindow: 'Abrir en una nueva ventana',
        useProtocol: 'Usar el protocolo predefinido',
      },
      table: {
        table: 'Tabla',
        addRowAbove: 'Añadir una fila encima',
        addRowBelow: 'Añadir una fila debajo',
        addColLeft: 'Añadir una columna a la izquierda',
        addColRight: 'Añadir una columna a la derecha',
        delRow: 'Borrar la fila',
        delCol: 'Borrar la columna',
        delTable: 'Borrar la tabla',
      },
      hr: {
        insert: 'Insertar una línea horizontal',
      },
      style: {
        style: 'Estilo',
        p: 'Normal',
        blockquote: 'Cita',
        pre: 'Código',
        h1: 'Título 1',
        h2: 'Título 2',
        h3: 'Título 3',
        h4: 'Título 4',
        h5: 'Título 5',
        h6: 'Título 6',
      },
      lists: {
        unordered: 'Lista',
        ordered: 'Lista numerada',
      },
      options: {
        help: 'Ayuda',
        fullscreen: 'Pantalla completa',
        codeview: 'Ver el código fuente',
      },
      paragraph: {
        paragraph: 'Párrafo',
        outdent: 'Reducir la sangría',
        indent: 'Aumentar la sangría',
        left: 'Alinear a la izquierda',
        center: 'Centrar',
        right: 'Alinear a la derecha',
        justify: 'Justificar',
      },
      color: {
        recent: 'Último color',
        more: 'Más colores',
        background: 'Color de fondo',
        foreground: 'Color del texto',
        transparent: 'Transparente',
        setTransparent: 'Establecer transparente',
        reset: 'Restablecer',
        resetToDefault: 'Restablecer a los valores predefinidos',
        cpSelect: 'Seleccionar',
      },
      shortcut: {
        shortcuts: 'Atajos de teclado',
        close: 'Cerrar',
        textFormatting: 'Formato de texto',
        action: 'Acción',
        paragraphFormatting: 'Formato de párrafo',
        documentStyle: 'Estilo de documento',
        extraKeys: 'Teclas adicionales',
      },
      help: {
        insertParagraph: 'Insertar un párrafo',
        undo: 'Deshacer la última acción',
        redo: 'Rehacer la última acción',
        tab: 'Tabular',
        untab: 'Eliminar tabulación',
        bold: 'Establecer estilo negrita',
        italic: 'Establecer estilo cursiva',
        underline: 'Establecer estilo subrayado',
        strikethrough: 'Establecer estilo tachado',
        removeFormat: 'Limpiar estilo',
        justifyLeft: 'Alinear a la izquierda',
        justifyCenter: 'Alinear al centro',
        justifyRight: 'Alinear a la derecha',
        justifyFull: 'Justificar',
        insertUnorderedList: 'Insertar lista',
        insertOrderedList: 'Insertar lista numerada',
        outdent: 'Reducir sangría del párrafo',
        indent: 'Aumentar sangría del párrafo',
        formatPara: 'Cambiar el formato del bloque actual a párrafo (etiqueta P)',
        formatH1: 'Cambiar el formato del bloque actual a H1',
        formatH2: 'Cambiar el formato del bloque actual a H2',
        formatH3: 'Cambiar el formato del bloque actual a H3',
        formatH4: 'Cambiar el formato del bloque actual a H4',
        formatH5: 'Cambiar el formato del bloque actual a H5',
        formatH6: 'Cambiar el formato del bloque actual a H6',
        insertHorizontalRule: 'Insertar una línea horizontal',
        'linkDialog.show': 'Mostrar el panel de enlaces',
      },
      history: {
        undo: 'Deshacer',
        redo: 'Rehacer',
      },
      specialChar: {
        specialChar: 'CARACTERES ESPECIALES',
        select: 'Seleccionar caracteres especiales',
      },
      output: {
        noSelection: '¡No ha seleccionado nada!',
      },
    },
  });
})(jQuery);

// (function ($) {
//   // see: https://github.com/DiemenDesign/summernote-templates
//   $.extend(true, $.summernote.lang, {
//     'es-ES': {
//       pageTemplates: {
//         tooltip: 'Seleccionar plantilla',
//         dialogTitle: 'Plantillas',
//         editBtn: 'Agregar'
//       }
//     }
//   });

//   $.extend($.summernote.plugins, {
//     'pageTemplates': function (context) {
//       var self = this;
//       var ui = $.summernote.ui;
//       var $note = context.layoutInfo.note;
//       var $editor = context.layoutInfo.editor;
//       var options = context.options;
//       var lang = options.langInfo;

//       context.memo('button.pageTemplates', function () {
//         var button = ui.button({
//           contents: '<i class="note-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width="14" height="14"><path d="m 10,10.696915 -6,0 0,-1.071288 6,0 z m -0.00114,-1.9788834 -2.3368368,0 0,-1.0712886 2.3368368,0 z m 0,-1.9759562 -2.3368368,0 0,-1.071288 2.3368368,0 z m -3.3029604,1.9759562 -2.6958966,0 0,-3.0472086 2.6958966,0 z M 7.640148,1.26517 C 7.49432,1.11934 7.20625,1 7,1 L 2.5,1 C 2.29375,1 2.125,1.16875 2.125,1.375 l 0,11.25 C 2.125,12.83125 2.29375,13 2.5,13 l 9,0 c 0.20625,0 0.375,-0.16875 0.375,-0.375 l 0,-6.75 c 0,-0.20625 -0.11932,-0.49432 -0.265148,-0.64015 L 7.640148,1.26517 Z M 11.125,12.25 l -8.25,0 0,-10.5 4.115133,0 c 0.03417,0.006 0.09853,0.0323 0.12668,0.0525 l 3.955734,3.95571 c 0.02018,0.0281 0.04683,0.0925 0.05245,0.12668 l 0,6.36513 z M 11.5,1 9.25,1 C 9.04375,1 8.99432,1.11932 9.140148,1.26515 l 2.46968,2.46968 C 11.75568,3.88068 11.875,3.83125 11.875,3.625 l 0,-2.25 C 11.875,1.16875 11.70625,1 11.5,1 Z"/></svg></i>',
//           tooltip: lang.pageTemplates.tooltip,
//           click: function (e) {
//             context.invoke('pageTemplates.show');
//           }
//         });

//         return button.render();
//       });

//       this.initialize = function () {
//         var $container = options.dialogsInBody ? $(document.body) : $editor;
//         var body = '<div class="note-pageTemplates" style="max-height:310px;overflow:auto;"></div>';

//         this.$dialog = ui.dialog({
//           title: lang.pageTemplates.dialogTitle,
//           body: body,
//           footer: '<button class="btn btn-primary note-pageTemplates-btn">' + lang.pageTemplates.editBtn + '</button>'
//         }).render().appendTo($container);
//       };

//       this.destroy = function () {
//         ui.hideDialog(this.$dialog);
//         this.$dialog.remove();
//       };

//       this.bindEnterKey = function ($input, $btn) {
//         $input.on('keypress', function (event) {
//           if (event.keyCode === 13) $btn.trigger('click');
//         });
//       };

//       this.bindLabels = function () {
//         self.$dialog.find('.form-control:first').focus().select();
//         self.$dialog.find('label').on('click', function () {
//           $(this).parent().find('.form-control:first').focus();
//         });
//       };

//       this.show = function () {
//         this.showpageTemplatesDialog();
//       };

//       this.showpageTemplatesDialog = function () {
//         return $.Deferred(function (deferred) {
//           var $pTBtn = self.$dialog.find('.note-pageTemplates-btn');

//           ui.onDialogShown(self.$dialog, function () {
//             context.triggerEvent('dialog.shown');
            
//             KTApp.block(self.$dialog, {
//               message: 'Cargando...'
//             });

//             $.get(route('admin.templates').toString()).done(function (templates) {
//               $('.note-pageTemplates').html('');

//               for(const template of templates) {
//                 $('.note-pageTemplates').append('<div class="col-xs-3"><label for="note-select-' + template.id + '" class="control-label note-pageTemplates-label"><input id="note-select-' + template.id + '" class="hidden" type="radio" value="' + template.id + '" name="note-pageTemplates-select">&nbsp;&nbsp;' + template.name + '</label></div>');
//               }

//               KTApp.unblock(self.$dialog);
//             });

//             $pTBtn.click(function (e) {
//               e.preventDefault();

//               var templateId = $('input[name=note-pageTemplates-select]:checked').val();

//               if (templateId) {
//                 $.get(route('templates', { id: templateId }).toString()).done(function (data) {
//                   $note.summernote('pasteHTML', data);
//                   ui.hideDialog(self.$dialog);
//                 });
//               }
//             });

//             self.bindEnterKey($pTBtn);
//             self.bindLabels();
//           });

//           this.destroy = function () {
//             ui.hideDialog(this.$dialog);
//             this.$dialog.remove();
//           };

//           ui.onDialogHidden(self.$dialog, function () {
//             $pTBtn.off('click');
//             if (deferred.state() === 'pending') deferred.reject();
//           });

//           ui.showDialog(self.$dialog);
//         });
//       };
//     }
//   });
// })(jQuery);