'use strict';

const init = (options) => {
    const modal = $('#repairs-modal--create-departure');
    const form = $('#modal-create-departure-form');
    const selectTrackingStatus = $('#modal-create-departure--status');
    const datePickerDeliveredAt = $('#modal-create-departure--delivered-at');

    selectTrackingStatus.select2();
    datePickerDeliveredAt.datepicker({
        todayHighlight: true,
        language: 'es',
        orientation: "bottom left",
        format: 'dd-mm-yyyy',
        startDate: new Date(),
        templates: {
            leftArrow: '<i class="la la-angle-left"></i>',
            rightArrow: '<i class="la la-angle-right"></i>'
        }
    }).datepicker("setDate", "0");

    return new Promise((resolve, reject) => {
        modal.modal();

        modal.on('hidden.bs.modal', (e) => {
            return resolve();
        });

        form.submit((e) => {
            e.preventDefault();

            KTApp.block(modal, {
                message: 'Cargando...'
            });

            $.ajax({
                data: form.serialize(),
                cache: false,
                url: form.attr('action').replace(':id', options.id),
                type: 'POST',
                success: (response) => {
                    modal.modal('hide');
                    KTApp.unblock(modal);

                    return resolve(response);
                },
                error: (response) => {
                    modal.modal('hide');
                    KTApp.unblock(modal);

                    return reject(response);
                }
            });
        });
    });
};

export default {
    init
};
