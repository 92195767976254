'use strict';

import userHelpers from "../../custom/users/helpers";

KTUtil.ready(() => {
    if (!$("#page-edit-user").length) {
        return null;
    }

    userHelpers.initCompanySelector();
    userHelpers.initInsurerSelector();
    userHelpers.initRolSelector();
    
    userHelpers.generatePassword();
});