'use strict';

import clientsTable from '../../custom/clients/table';

KTUtil.ready(() => {
    if (!$("#page-all-clients").length) {
        return null;
    }

    $('.location_selector').select2({
        placeholder: 'Seleccione una localidad',
        minimumInputLength: 3,
        ajax: {
            url: route('admin.locations.all').toString(),
            dataType: 'json',
            delay: 300,
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            // @TODO we should use helper (all selector will have the same function)
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                // NOTE: This is to be able to remove the filter. We can not use null values with select2 but Laravel convert empty strings to null
                results.unshift({
                    id: ' ',
                    text: 'Sin filtrar',
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    clientsTable.init();
});
