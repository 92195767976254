'use strict';

import companiesTable from '../../custom/companies/table';

KTUtil.ready(() => {
    if (!$("#page-all-companies").length) {
        return null;
    }

    companiesTable.init();
});
