'use strict';

KTUtil.ready(() => {
    $('.menu-item').each((index, item) => {
        const menuItem = $(item);
        const anchor = menuItem.children('a');
        const href = anchor ? anchor.attr('href') : '';
        let currentPath = window.location.pathname;

        if (currentPath[0] === '/') {
            currentPath = currentPath.slice(1);
        }

        currentPath = currentPath.split('/').slice(0,2).join('/');

        if (!currentPath.length && index === 0) {
            menuItem.addClass('menu-item-active');
        }

        if (href !== '#' && href !== 'javascript:;' && currentPath.length && href.indexOf(currentPath) >= 0) {
            menuItem.addClass('menu-item-active');

            // Aside menu items can have one or more parents
            if (menuItem.parents('.menu-item-submenu').length) {
                menuItem.parents('.menu-item-submenu').addClass('menu-item-open menu-item-here');
            }
        }
    });
});
