"use strict";
//
// Handle User Quick Search For Dropdown, Inline and Offcanvas Search Panels
//

var KTLayoutSearch = function() {
    // Private properties
    var _target;
    var _form;
    var _input;
    var _closeIcon;
    var _resultWrapper;
    var _resultDropdown;
    var _resultDropdownToggle;
    var _closeIconContainer;
    var _inputGroup;
    var _query = '';

    var _hasResult = false;
    var _timeout = false;
    var _isProcessing = false;
    var _requestTimeout = 200; // ajax request fire timeout in milliseconds
    var _spinnerClass = 'spinner spinner-sm spinner-primary';
    var _resultClass = 'quick-search-has-result';
    var _minLength = 2;

    // Private functions
    var _showProgress = function() {
        _isProcessing = true;
        KTUtil.addClass(_closeIconContainer, _spinnerClass);

        if (_closeIcon) {
            KTUtil.hide(_closeIcon);
        }
    }

    var _hideProgress = function() {
        _isProcessing = false;
        KTUtil.removeClass(_closeIconContainer, _spinnerClass);

        if (_closeIcon) {
            if (_input.value.length < _minLength) {
                KTUtil.hide(_closeIcon);
            } else {
                KTUtil.show(_closeIcon, 'flex');
            }
        }
    }

    var _showDropdown = function() {
        if (_resultDropdownToggle && !KTUtil.hasClass(_resultDropdown, 'show')) {
            $(_resultDropdownToggle).dropdown('toggle');
            $(_resultDropdownToggle).dropdown('update');
        }
    }

    var _hideDropdown = function() {
        if (_resultDropdownToggle && KTUtil.hasClass(_resultDropdown, 'show')) {
            $(_resultDropdownToggle).dropdown('toggle');
        }
    }

    var _processSearch = function() {
        if (_hasResult && _query === _input.value) {
            _hideProgress();
            KTUtil.addClass(_target, _resultClass);
            _showDropdown();
            KTUtil.scrollUpdate(_resultWrapper);

            return;
        }

        _query = _input.value;

        KTUtil.removeClass(_target, _resultClass);
        _showProgress();
        _hideDropdown();

        $.ajax({
            url: _form.action,
            data: {
                query: {
                    general_search: _query,
                },
                pagination: {
                    page: 1,
                    perpage: 20,
                }
            },
            //dataType: 'html',
            success: function(res) {
                const data = _parseResponse(res);
                _hasResult = true;
                _hideProgress();
                KTUtil.addClass(_target, _resultClass);
                KTUtil.setHTML(_resultWrapper, data);
                _showDropdown();
                KTUtil.scrollUpdate(_resultWrapper);
            },
            error: function(res) {
                _hasResult = false;
                _hideProgress();
                KTUtil.addClass(_target, _resultClass);
                KTUtil.setHTML(_resultWrapper, '<span class="font-weight-bold text-muted">Sin resultados...</div>');
                _showDropdown();
                KTUtil.scrollUpdate(_resultWrapper);

                let text = 'No se pudo realizar la operación. Contacte con el equipo técnico';

                if (res && res.status) {
                    text += `. Código de error: ${res.status}`
                }

                swal.fire({
                    title: 'Oops... Algo salió mal 😢',
                    text,
                    icon: 'error'
                });
            }
        });
    }

    var _handleCancel = function(e) {
        _input.value = '';
        _query = '';
        _hasResult = false;
        KTUtil.hide(_closeIcon);
        KTUtil.removeClass(_target, _resultClass);
        _hideDropdown();
    }

    var _handleSearch = function(e) {
        if (e.key !== 'Enter' || e.which !== 13) {
            return;
        }

        if (_input.value.length < _minLength) {
            _hideProgress();
            _hideDropdown();

            return;
        }

        if (_isProcessing == true) {
            return;
        }

        if (_timeout) {
            clearTimeout(_timeout);
        }

        _timeout = setTimeout(function() {
            _processSearch();
        }, _requestTimeout);
    }

    var _parseResponse = function(response) {
        let html = '';

        if (!response.data || !response.data.length) {
            return '<span class="font-weight-bold text-muted">Sin resultados...</div>';
        }

        $(response.data).each((index, obj) => {
            const item = `
                <div class="d-flex align-items-center flex-grow-1 mb-2">
                    <div class="d-flex flex-column ml-3 mt-2 mb-2">
                        <a href="${route('admin.repairs.edit', {id: obj.id}).toString()}" class="font-weight-bold text-dark text-hover-primary">
                            Reparación #${obj.id} | ${obj.product.name}
                        </a>
                        <span class="font-size-sm font-weight-bold text-muted">
                            Cliente: ${obj.client.first_name ? obj.client.last_name + ", " + obj.client.first_name : obj.client.last_name} (${obj.client.id})
                        </span>
                    </div>
                </div>
            `;

            html += item;
        });

        return html;
    }

    // Public methods
    return {
        init: function(id) {
            _target = KTUtil.getById(id);

            if (!_target) {
                return;
            }

            _form = KTUtil.find(_target, '.quick-search-form');
            _input = KTUtil.find(_target, '.form-control');
            _closeIcon = KTUtil.find(_target, '.quick-search-close');
            _resultWrapper = KTUtil.find(_target, '.quick-search-wrapper');
            _resultDropdown = KTUtil.find(_target, '.dropdown-menu');
            _resultDropdownToggle = KTUtil.find(_target, '[data-toggle="dropdown"]');
            _inputGroup = KTUtil.find(_target, '.input-group');
            _closeIconContainer = KTUtil.find(_target, '.input-group .input-group-append');

            // Attach input keyup handler
            KTUtil.addEvent(_input, 'keyup', _handleSearch);
            KTUtil.addEvent(_input, 'focus', _handleSearch);

            // Prevent enter click
            _form.onkeypress = function(e) {
                var key = e.charCode || e.keyCode || 0;
                if (key == 13) {
                    e.preventDefault();
                }
            }

            KTUtil.addEvent(_closeIcon, 'click', _handleCancel);
        }
    };
};

// Webpack support
if (typeof module !== 'undefined') {
    module.exports = KTLayoutSearch;
}

var KTLayoutSearchInline = KTLayoutSearch;
var KTLayoutSearchOffcanvas = KTLayoutSearch;
