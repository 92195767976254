'use strict';

import emailsTable from '../../custom/emails/table';

KTUtil.ready(() => {
    if (!$("#page-all-emails").length) {
        return null;
    }

    emailsTable.init();
});
