'use strict';

import reportsTable from '../../custom/reports/table';

KTUtil.ready(() => {
    if (!$("#page-all-reports").length) {
        return null;
    }

    reportsTable.init();
});
