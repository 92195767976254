'use strict';

import deleteTrackingStatusModal from './modal-delete';
import restoreTrackingStatusModal from './modal-restore';

// Private functions and attributes
const inputSearch = $('#tracking-statuses-table--search');
const selectFilterField = $('#tracking-statuses-table--field-filter');
const selectStatus = $('#tracking-statuses-table--status-filter');
const columnSwitcher = ('.column_switcher');

const init = (query) => {
    // We MUST get table element this way (we can't create global variable)
    const table = $('#tracking-statuses-table');
    const filteringByRepair = query?.filter_by === 'repair_id';

    const columns = [
        {
            field: 'name',
            title: 'Nombre',
            sortable: !filteringByRepair,
            autoHide: false
        }, {
            field: 'deleted_at',
            title: 'Estado',
            sortable: !filteringByRepair,
            autoHide: false,
            textAlign: 'center',
            template: (row) => {
                let text = 'Desconocido';
                const classes = [
                    'label',
                    'label-light-primary',
                    'label-inline',
                    'font-weight-bold',
                    'label-lg'
                ];

                if (row.deleted_at) {
                    text = 'Desactivado';
                    classes.push('label-light-danger');
                } else {
                    text = 'Habilitado';
                    classes.push('label-light-primary');
                }

                return `
                    <span class="${classes.join(' ')}">
                        ${text}
                    </span>
                `;
            }
        }, {
            field: filteringByRepair ? 'pivot_created_at' : 'created_at',
            title: filteringByRepair ? 'Fecha' : 'Fecha de creación',
            sortable: !filteringByRepair,
            autoHide: false,
            filterable: false,
            type: 'datetime',
            template: (row) => {
                if (row.pivot_created_at) {
                    return moment(row.pivot_created_at).format('DD-MM-YYYY');
                }

                return moment(row.created_at).format('DD-MM-YYYY');
            }
        }
    ];

    // NOTE: On this table, we can't show "actions" column if we are listing tracking statuses of an specific repair
    if (!filteringByRepair) {
        columns.unshift({
            field: 'id',
            title: '#',
            sortable: true,
            autoHide: false,
            textAlign: 'center',
            template: (row, index, datatable) => {
                const dropup = (datatable.getPageSize() - index) <= 4 ? 'dropup' : '';
                const options = [];
                let html = '';

                if (permissions.includes('UPDATE_STATUSES')) {
                    options.push({
                        id: row.id,
                        class: 'tracking_status_edit_btn',
                        icon: 'la la-edit',
                        text: 'Editar'
                    });
                }

                if (permissions.includes('RESTORE_STATUSES') || permissions.includes('DELETE_STATUSES')) {
                    options.push({
                        divider: true,
                    });
                }

                if (row.deleted_at && permissions.includes('RESTORE_STATUSES')) {
                    options.push({
                        id: row.id,
                        class: 'tracking_status_restore_btn',
                        icon: 'la la-recycle',
                        text: 'Restaurar'
                    });
                }

                if (!row.deleted_at && permissions.includes('DELETE_STATUSES')) {
                    options.push({
                        id: row.id,
                        class: 'tracking_status_delete_btn',
                        icon: 'la la-trash',
                        text: 'Desactivar'
                    });
                }

                if (!options.length) {
                    return row.id;
                }

                html = `
                    <div class="dropdown dropdown-inline ${dropup}">
                        <a href="#" class="btn btn-text-dark btn-hover-light font-weight-bold" data-toggle="dropdown">
                            <i class="flaticon-more-v2"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right actions">
                            ${
                                options.map((option) => {
                                    if (option.divider) {
                                        return '<div class="dropdown-divider"></div>';
                                    }

                                    return `
                                        <button data-id="${option.id}" class="dropdown-item ${option.class}">
                                            <i class="${option.icon} mr-2"></i> ${option.text}
                                        </button>
                                    `;
                                }).join('')
                            }
                        </div>
                    </div>

                    ${row.id}
                `;

                return html;
            }
        });
    } else {
        columns.unshift({
            field: 'id',
            title: '#',
            sortable: false,
            autoHide: false,
            textAlign: 'center',
        });
    }

    if (!table || !table.length) {
        return;
    }

    // NOTE: As we show this table in the repairs sections, this selector may not exist
    if (selectStatus && selectStatus.length) {
        $([selectStatus]).select2();
    }
    
    const datatable = table.KTDatatable({
        // datasource definition
        data: {
            type: 'remote',
            source: {
                read: {
                    method: 'GET',
                    url: route('admin.tracking-statuses.all').toString(),
                    params: {
                        query
                    }
                },
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            // NOTE: Prevents save state when we are filtering by repair
            saveState: filteringByRepair,
        },

        // layout definition
        layout: {
            customScrollbar: false,
            scroll: true,
            // height: 400,
            footer: false,
            header: true,
            icons: {
                rowDetail: null
            }
        },

        // column sorting
        sortable: true,

        pagination: true,

        search: {
            onEnter: true,
            input: inputSearch,
        },

        // columns definition
        columns
    });

    /**
     * Table filter events
     */
    $(selectStatus).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'deleted_at');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectFilterField).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'filter_by');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(columnSwitcher).change((e) => {
        const field = e.currentTarget.name;
        const active = e.currentTarget.checked;

        if (!datatable.getColumnByField(field)) {
            return;
        }

        datatable.columns(field).visible(active);
        datatable.stateKeep('columns', datatable.options.columns);
    });

    /**
     * Datatable events
     */
    datatable.on('datatable-on-init', () => {
        const tableMeta = datatable.stateGet('meta');
        const tableColumns = datatable.stateGet('columns');

        if (tableMeta && tableMeta.query && tableMeta.query.location_id) {
            $(selectLocation)
                .val(tableMeta.query.location_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.filter_by) {
            $(selectFilterField).val(tableMeta.query.filter_by);
        }

        if (tableMeta && tableMeta.query && tableMeta.query.general_search) {
            $(inputSearch).val(tableMeta.query.general_search);
        }

        if (!tableColumns) {
            return null;
        }

        // Update table column visibility switchers
        $.each(tableColumns, function(index, obj) {
            const isVisible = obj.visible === undefined || obj.visible === true;

            datatable.columns(obj.field).visible(isVisible);
            datatable.stateKeep('columns', datatable.options.columns);
            $(`input[name="${obj.field}"]`).prop("checked", isVisible);
        });
    });

    datatable.on('datatable-on-ajax-fail', (jqXHR) => {
        let text = 'No se pudo realizar la operación. Contacte con el equipo técnico';

        if (jqXHR && jqXHR.status) {
            text += `. Código de error: ${jqXHR.status}`
        }

        swal.fire({
            title: 'Oops... Algo salió mal 😢',
            text,
            icon: 'error'
        });
    });

    /**
     * Table action events
     */
    $(document.body).on('click', 'button.tracking_status_edit_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.tracking-statuses.edit', {id: target.data('id')}).toString();

        window.open(url, '_self');
    });

    $(document.body).on('click', 'button.tracking_status_delete_btn', (e) => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const options = {
            id: target.data('id'),
        };

        KTApp.block(table, {
            message: 'Cargando...'
        });

        deleteTrackingStatusModal.init(options)
            .then((response) => {
                if (response) {
                    datatable.reload();
                }

                KTApp.unblock(table);
            })
            .catch(() => {
                datatable.reload();

                KTApp.unblock(table);
            });
    });

    $(document.body).on('click', 'button.tracking_status_restore_btn', (e) => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const options = {
            id: target.data('id'),
        };

        KTApp.block(table, {
            message: 'Cargando...'
        });

        restoreTrackingStatusModal.init(options)
            .then((response) => {
                if (response) {
                    datatable.reload();
                }

                KTApp.unblock(table);
            })
            .catch(() => {
                datatable.reload();

                KTApp.unblock(table);
            });
    });
};

const destroy = () => {
    // We MUST get table element this way (we can't create global variable)
    $('#tracking-statuses-table').KTDatatable('destroy');
};

export default {
    init,
    destroy
}
