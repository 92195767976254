'use strict';

import deleteProductModal from './modal-delete';
import restoreProductModal from './modal-restore';

// Private functions and attributes
const inputSearch = $('#products-table--search');
const selectFilterField = $('#products-table--field-filter');
const selectStatus = $('#products-table--status-filter');
const columnSwitcher = ('.column_switcher');

const init = () => {
    // We MUST get table element this way (we can't create global variable)
    const table = $('#products-table');

    if (!table || !table.length) {
        return;
    }

    $([selectStatus]).select2();
    const datatable = table.KTDatatable({
        // datasource definition
        data: {
            type: 'remote',
            source: {
                read: {
                    method: 'GET',
                    url: route('admin.products.all').toString(),
                },
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            saveState: true,
        },

        // layout definition
        layout: {
            customScrollbar: false,
            scroll: true,
            // height: 400,
            footer: false,
            header: true,
            icons: {
                rowDetail: null
            }
        },

        // column sorting
        sortable: true,

        pagination: true,

        search: {
            onEnter: true,
            input: inputSearch,
        },

        // columns definition
        columns: [
            {
                field: 'id',
                title: '#',
                sortable: true,
                autoHide: false,
                textAlign: 'center',
                template: (row, index, datatable) => {
                    const dropup = (datatable.getPageSize() - index) <= 4 ? 'dropup' : '';
                    const options = [];
                    let html = '';
    
                    if (permissions.includes('UPDATE_PRODUCTS')) {
                        options.push({
                            id: row.id,
                            class: 'product_edit_btn',
                            icon: 'la la-edit',
                            text: 'Editar'
                        });
                    }
    
                    if (permissions.includes('RESTORE_PRODUCTS') || permissions.includes('DELETE_PRODUCTS')) {
                        options.push({
                            divider: true,
                        });
                    }
    
                    if (row.deleted_at && permissions.includes('RESTORE_PRODUCTS')) {
                        options.push({
                            id: row.id,
                            class: 'product_restore_btn',
                            icon: 'la la-recycle',
                            text: 'Restaurar'
                        });
                    }
    
                    if (!row.deleted_at && permissions.includes('DELETE_PRODUCTS')) {
                        options.push({
                            id: row.id,
                            class: 'product_delete_btn',
                            icon: 'la la-trash',
                            text: 'Desactivar'
                        });
                    }
    
                    if (!options.length) {
                        return row.id;
                    }
    
                    html = `
                        <div class="dropdown dropdown-inline ${dropup}">
                            <a href="#" class="btn btn-text-dark btn-hover-light font-weight-bold" data-toggle="dropdown">
                                <i class="flaticon-more-v2"></i>
                            </a>
    
                            <div class="dropdown-menu dropdown-menu-right actions">
                                ${
                                    options.map((option) => {
                                        if (option.divider) {
                                            return '<div class="dropdown-divider"></div>';
                                        }
    
                                        return `
                                            <button data-id="${option.id}" class="dropdown-item ${option.class}">
                                                <i class="${option.icon} mr-2"></i> ${option.text}
                                            </button>
                                        `;
                                    }).join('')
                                }
                            </div>
                        </div>

                        ${row.id}
                    `;
    
                    return html;
                }
            }, {
                field: 'name',
                title: 'Nombre',
                sortable: true,
                autoHide: false
            }, {
                field: 'deleted_at',
                title: 'Estado',
                sortable: true,
                autoHide: false,
                textAlign: 'center',
                template: (row) => {
                    let text = 'Desconocido';
                    const classes = [
                        'label',
                        'label-light-primary',
                        'label-inline',
                        'font-weight-bold',
                        'label-lg'
                    ];
    
                    if (row.deleted_at) {
                        text = 'Desactivado';
                        classes.push('label-light-danger');
                    } else {
                        text = 'Habilitado';
                        classes.push('label-light-primary');
                    }
    
                    return `
                        <span class="${classes.join(' ')}">
                            ${text}
                        </span>
                    `;
                }
            }, {
                field: 'created_at',
                title: 'Fecha de creación',
                sortable: true,
                autoHide: false,
                filterable: false,
                type: 'datetime',
                template: (row) => {
                    return moment(row.created_at).format('DD-MM-YYYY');
                }
            }
        ],
    });

    /**
     * Table filter events
     */
    $(selectStatus).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'deleted_at');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectFilterField).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'filter_by');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(columnSwitcher).change((e) => {
        const field = e.currentTarget.name;
        const active = e.currentTarget.checked;

        if (!datatable.getColumnByField(field)) {
            return;
        }

        datatable.columns(field).visible(active);
        datatable.stateKeep('columns', datatable.options.columns);
    });

    /**
     * Datatable events
     */
    datatable.on('datatable-on-init', () => {
        const tableMeta = datatable.stateGet('meta');
        const tableColumns = datatable.stateGet('columns');

        if (tableMeta && tableMeta.query && tableMeta.query.location_id) {
            $(selectLocation)
                .val(tableMeta.query.location_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.filter_by) {
            $(selectFilterField).val(tableMeta.query.filter_by);
        }

        if (tableMeta && tableMeta.query && tableMeta.query.general_search) {
            $(inputSearch).val(tableMeta.query.general_search);
        }

        if (!tableColumns) {
            return null;
        }

        // Update table column visibility switchers
        $.each(tableColumns, function(index, obj) {
            const isVisible = obj.visible === undefined || obj.visible === true;

            datatable.columns(obj.field).visible(isVisible);
            datatable.stateKeep('columns', datatable.options.columns);
            $(`input[name="${obj.field}"]`).prop("checked", isVisible);
        });
    });

    datatable.on('datatable-on-ajax-fail', (jqXHR) => {
        let text = 'No se pudo realizar la operación. Contacte con el equipo técnico';

        if (jqXHR && jqXHR.status) {
            text += `. Código de error: ${jqXHR.status}`
        }

        swal.fire({
            title: 'Oops... Algo salió mal 😢',
            text,
            icon: 'error'
        });
    });

    /**
     * Table action events
     */
    $(document.body).on('click', 'button.product_edit_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.products.edit', {id: target.data('id')}).toString();

        window.open(url, '_self');
    });

    $(document.body).on('click', 'button.product_delete_btn', (e) => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const options = {
            id: target.data('id'),
        };

        KTApp.block(table, {
            message: 'Cargando...'
        });

        deleteProductModal.init(options)
            .then((response) => {
                if (response) {
                    datatable.reload();
                }

                KTApp.unblock(table);
            })
            .catch(() => {
                datatable.reload();

                KTApp.unblock(table);
            });
    });

    $(document.body).on('click', 'button.product_restore_btn', (e) => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const options = {
            id: target.data('id'),
        };

        KTApp.block(table, {
            message: 'Cargando...'
        });

        restoreProductModal.init(options)
            .then((response) => {
                if (response) {
                    datatable.reload();
                }

                KTApp.unblock(table);
            })
            .catch(() => {
                datatable.reload();

                KTApp.unblock(table);
            });
    });
};

const destroy = () => {
    // We MUST get table element this way (we can't create global variable)
    $('#products-table').KTDatatable('destroy');
};

export default {
    init,
    destroy
}
