// External plugins
window.jQuery = window.$ = require('jquery');
require('bootstrap');
window.Popper = require('popper.js').default;
window.Tooltip = require('tooltip.js').default;
window.wNumb = require('wnumb');
window.moment = require('moment');
window.Sticky = require('sticky-js');
window.PerfectScrollbar = require('perfect-scrollbar/dist/perfect-scrollbar');
window.ApexCharts = require('apexcharts/dist/apexcharts.min.js');
require("es6-shim/es6-shim.min.js");
window.FormValidation = require("../plugins/formvalidation/dist/amd/index.js");
window.FormValidation.plugins.Bootstrap = require("../plugins/formvalidation/dist/amd/plugins/Bootstrap.js").default;
require('jquery-validation/dist/jquery.validate.min.js');
require('jquery-form/dist/jquery.form.min.js');
require('block-ui/jquery.blockUI.js');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker.js');
require('./vendors/plugins/bootstrap-datepicker.init.js');
require('bootstrap-datetime-picker/js/bootstrap-datetimepicker.js');
require('bootstrap-timepicker/js/bootstrap-timepicker.js');
require('./vendors/plugins/bootstrap-timepicker.init.js');
require('bootstrap-daterangepicker/daterangepicker.js');
require('bootstrap-touchspin/dist/jquery.bootstrap-touchspin.js');
require('bootstrap-maxlength/src/bootstrap-maxlength.js');
require('../plugins/bootstrap-multiselectsplitter/bootstrap-multiselectsplitter.min.js');
require('bootstrap-select/dist/js/bootstrap-select.js');
require('bootstrap-switch/dist/js/bootstrap-switch.js');
require('./vendors/plugins/bootstrap-switch.init.js');
require('select2/dist/js/select2.full.js');
require('ion-rangeslider/js/ion.rangeSlider.js');
window.Bloodhound = require('typeahead.js/dist/typeahead.bundle.js');
window.Handlebars = require('handlebars/dist/handlebars.js');
require('inputmask/dist/jquery.inputmask.bundle.js');
require('inputmask/dist/inputmask/inputmask.date.extensions.js');
require('inputmask/dist/inputmask/inputmask.numeric.extensions.js');
window.noUiSlider = require('nouislider/distribute/nouislider.js');
require('owl.carousel/dist/owl.carousel');
window.autosize = require('autosize/dist/autosize.js');
window.ClipboardJS = require('clipboard/dist/clipboard.min.js');
window.Dropzone = require('dropzone/dist/dropzone.js');
require('./vendors/plugins/dropzone.init.js');
window.Quill = require('quill/dist/quill.js');
require('@yaireo/tagify/dist/tagify.polyfills.min');
window.Tagify = require('@yaireo/tagify/dist/tagify.min');
require('summernote/dist/summernote.js');
require('markdown/lib/markdown.js');
require('bootstrap-markdown/js/bootstrap-markdown.js');
require('./vendors/plugins/bootstrap-markdown.init.js');
require('bootstrap-notify/bootstrap-notify.min.js');
require('./vendors/plugins/bootstrap-notify.init.js');
window.toastr = require('toastr/build/toastr.min.js');
window.DualListbox = require('dual-listbox').default;
window.sessionTimeout = require('../plugins/bootstrap-session-timeout/dist/bootstrap-session-timeout.min.js');
require('../plugins/jquery-idletimer/idle-timer.min.js');
require('waypoints/lib/jquery.waypoints.js');
require('counterup/jquery.counterup.js');
require('es6-promise-polyfill/promise.min.js');
window.swal = require('sweetalert2/dist/sweetalert2.min.js');
require('./vendors/plugins/sweetalert2.init.js');
window.Sticky = require('sticky-js/dist/sticky.min.js');
window.lightbox = require('lightbox2/dist/js/lightbox.js');

// Keenthemes' plugins
window.KTAppSettings = require('./config.js');
window.KTUtil = require('./components/util.js');
window.KTApp = require('./components/app.js');
window.KTCard = require('./components/card.js');
window.KTCookie = require('./components/cookie.js');
window.KTDialog = require('./components/dialog.js');
window.KTHeader = require('./components/header.js');
window.KTImageInput = require('./components/image-input.js');
window.KTMenu = require('./components/menu.js');
window.KTOffcanvas = require('./components/offcanvas.js');
window.KTScrolltop = require('./components/scrolltop.js');
window.KTToggle = require('./components/toggle.js');
window.KTWizard = require('./components/wizard.js');
require('./components/datatable/core.datatable.js');
require('./components/datatable/datatable.checkbox.js');
require('./components/datatable/datatable.rtl.js');

// Metronic layout base js
window.KTLayoutAside = require('./layout/base/aside.js');
window.KTLayoutAsideMenu = require('./layout/base/aside-menu.js');
window.KTLayoutAsideToggle = require('./layout/base/aside-toggle.js');
window.KTLayoutBrand = require('./layout/base/brand.js');
window.KTLayoutContent = require('./layout/base/content.js');
window.KTLayoutFooter = require('./layout/base/footer.js');
window.KTLayoutHeader = require('./layout/base/header.js');
window.KTLayoutHeaderMenu = require('./layout/base/header-menu.js');
window.KTLayoutHeaderTopbar = require('./layout/base/header-topbar.js');
window.KTLayoutSubheader = require('./layout/base/subheader.js');

// Metronic layout extended js
window.KTLayoutChat = require('./layout/extended/chat.js');
window.KTLayoutDemoPanel = require('./layout/extended/demo-panel.js');
window.KTLayoutExamples = require('./layout/extended/examples.js');
window.KTLayoutQuickActions = require('./layout/extended/quick-actions.js');
window.KTLayoutQuickCartPanel = require('./layout/extended/quick-cart.js');
window.KTLayoutQuickNotifications = require('./layout/extended/quick-notifications.js');
window.KTLayoutQuickPanel = require('./layout/extended/quick-panel.js');
window.KTLayoutQuickPanel = require('./layout/extended/quick-panel.js');
window.KTLayoutQuickSearch = require('./layout/extended/quick-search.js');
window.KTLayoutQuickUser = require('./layout/extended/quick-user.js');
window.KTLayoutScrolltop = require('./layout/extended/scrolltop.js');
window.KTLayoutSearch = window.KTLayoutSearchOffcanvas = require('./layout/extended/search.js');
window.KTLayoutStickyCard = require('./layout/extended/sticky-card.js');
window.KTLayoutStretchedCard = require('./layout/extended/stretched-card.js');

// Layout
require('./custom/common.js');
require('./custom/menu.js');
require('./custom/tasks-report.js');
require('./layout/initialize.js');

// Pluggins
require('./custom/multiselect.js');

// Vendors
require('./vendors/plugins/summernote.js');
require('./vendors/plugins/select2.init.js');
require('./vendors/plugins/datatables.init.js');
require('./vendors/plugins/lightbox.init');

// TecMaster page js
require('./pages/auth/all.js');
require('./pages/brands/all.js');
require('./pages/clients/all.js');
require('./pages/clients/create.js');
require('./pages/clients/edit.js');
require('./pages/companies/all.js');
require('./pages/emails/all.js');
require('./pages/insurers/all.js');
require('./pages/products/all.js');
require('./pages/repairs/all.js');
require('./pages/repairs/create.js');
require('./pages/repairs/edit.js');
require('./pages/reports/all.js');
require('./pages/reports/edit.js');
require('./pages/roles/all.js');
require('./pages/roles/create.js');
require('./pages/roles/edit.js');
require('./pages/tracking-statuses/all.js');
require('./pages/users/all.js');
require('./pages/users/create.js');
require('./pages/users/edit.js');
require('./pages/home/home.js');