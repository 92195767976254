'use strict';

KTUtil.ready(() => {
    if (!$("#page-home").length) {
        return null;
    }

    const sendContactEmail = (url, form) => {
        $.ajax({
            type: "POST",
            url,
            data: form.serialize(),
            success: function() {
                form.find('button').removeClass('spinner spinner-white spinner-right');
                return alert('Mensaje enviado correctamente');
            },
            error: function() {
                form.find('button').removeClass('spinner spinner-white spinner-right');
                return alert('Ocurrió un error, intentelo más tarde');
            }
        });
    }

    // NOTE: These functions should be defined in the global scope, so captcha will be able to find them
    window.onSubmitClientEmail = () => {
        var form = $("#form-contact-clients");
        var url = form.attr('action');

        sendContactEmail(url, form);
    }

    window.onSubmitInsurerEmail = () => {
        var form = $("#form-contact-insurers");
        var url = form.attr('action');

        sendContactEmail(url, form);
    }

    $('#tracking-btn').on('click', function (e) {
        const uid = $('input[name="uid"]').val();

        if (!uid || !uid.length) {
            $(this).removeClass('spinner spinner-white spinner-right');
        }

        const link = route('repairs.get', {
            uid
        }).toString();

        window.location.href = link;
    });
});
