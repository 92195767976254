'use strict';

import userHelpers from "../../custom/users/helpers";

KTUtil.ready(() => {
    if (!$("#page-create-user").length) {
        return null;
    }

    const insurerSelect = $('select[name="insurer_id"]');
    const companySelect = $('select[name="company_id"]');

    userHelpers.initCompanySelector();
    userHelpers.initInsurerSelector();
    userHelpers.initRolSelector();
    
    userHelpers.generatePassword();

    insurerSelect.on('select2:select', function (e) {
        companySelect.val(null);
        companySelect.prop('disabled', true);
    });

    companySelect.on('select2:select', function (e) {
        insurerSelect.val(null);
        insurerSelect.prop('disabled', true);
    });
});