'use strict';

import trackingStatusesTable from './table';

const init = (options) => {
    const modal = $('#repairs-modal--tracking-statuses');

    return new Promise((resolve) => {
        modal.modal();

        modal.on('hidden.bs.modal', (e) => {
            trackingStatusesTable.destroy();
            return resolve();
        });

        trackingStatusesTable.init(options);
    });
};

export default {
    init
};
