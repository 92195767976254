'use strict';

KTUtil.ready(() => {
    // We need to get access to user permissions in js files
    window.permissions = $('#ups').data('ups');

    $('.allow-focus').click((e) => {
        e.stopPropagation();
    });

    // As a product requirement, some inputs should be uppercased always
    $('.uppercased').each((index, element) => {
        $(element).keyup((e) => {
            e.currentTarget.value = e.currentTarget.value.toUpperCase();
        });

    });

    // Prevent users from submitting a form by hitting Enter (except for the create repair form)
    $(document).on("keydown", "form", function(event) { 
        return event.key !== "Enter" || $(event.target).attr('id') === 'clients-search';
    });

    // We should focus de input search input when the user clicks on the search icon
    $('#quick-search-icon').on('click', (e) => {
        setTimeout(() => {
            $('#quick-search-input').focus();
        }, 500);
    });

    // We should add a spinner to all submit button becasue the page may take time to load
    $('button[type="submit"]').on('click', function (e) {
        $(this)
            .toggleClass('px-9')
            .toggleClass('spinner spinner-white spinner-right');
    });

    // We should disable buttons on click to prevent perfom extra requests
    $('.disable-on-click').on('click', function (e) {
        e.preventDefault();

        if (!$(this).is(":disabled")) {
            $(this).parents('form').trigger('submit');
            $(this).attr("disabled", true); 
        }
    });

    // Global AJAX setup
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    // Global event handler for all ajax errors
    $(document).ajaxError((event, jqXHR) => {
        // Ignore aborted requests
        if (jqXHR?.statusText === 'abort') {
            return null;
        }

        let text = 'No se pudo realizar la operación. Contacte con el equipo técnico';

        if (jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.message) {
            text = jqXHR.responseJSON.message
        }

        if (jqXHR && jqXHR.status) {
            text += `. Código de error: ${jqXHR.status}`
        }

        swal.fire({
            title: 'Oops... Algo salió mal 😢',
            text,
            icon: 'error'
        });
    });
});
