'use strict';

import brandsTable from '../../custom/brands/table';

KTUtil.ready(() => {
    if (!$("#page-all-brands").length) {
        return null;
    }

    brandsTable.init();
});
