const initInsurerSelector = () => {
    $('.insurer_selector').select2({
        placeholder: 'Seleccione una compañía',
        minimumInputLength: 0,
        ajax: {
            url: route('admin.insurers.all').toString(),
            dataType: 'json',
            delay: 400,
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }
    
                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                // NOTE: This is to be able to remove the filter. We can not use null values with select2 but Laravel convert empty strings to null
                results.unshift({
                    id: ' ',
                    text: 'Ninguna',
                });
    
                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });
}

// @TODO: estas funciones deberian estar dentro de cada resource (companies en este caso) y  recibir como param opcional el selector
const initCompanySelector = () => {
    $('.company_selector').select2({
        placeholder: 'Seleccione una empresa',
        minimumInputLength: 0,
        ajax: {
            url: route('admin.companies.all').toString(),
            dataType: 'json',
            delay: 300,
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }
    
                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                // NOTE: This is to be able to remove the filter. We can not use null values with select2 but Laravel convert empty strings to null
                results.unshift({
                    id: ' ',
                    text: 'Ninguna',
                });
    
                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });
}

const initRolSelector = () => {
    $('.role_selector').select2({
        placeholder: 'Seleccione un rol',
        minimumInputLength: 3,
        ajax: {
            url: route('admin.roles.all').toString(),
            dataType: 'json',
            delay: 300,
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }
    
                const results = response.data.map((item) => {
                    return {
                        id: item.name,
                        text: item.name_visible
                    }
                });
    
                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });
}

const generatePassword = () => {
    const button = $(".generate_password");

    if (!button || !button.length) {
        return;
    }

    button.on('click', function(e) {
        e.preventDefault();

        var randomstring = Math.random().toString(36).slice(-26);

        button.parent('div').siblings('input[name="password"]').val(randomstring);
    });
};

export default {
    initInsurerSelector,
    initCompanySelector,
    initRolSelector,
    generatePassword
}
