'use strict';

KTUtil.ready(() => {
    if (!$("#page-edit-repair").length) {
        return null;
    }

    const spareCost = $('input[name="spare_cost"]');
    const laborCost = $('input[name="labor_cost"]');
    const totalAmount = $('.total_amount');
    const updateTotal = () => {
        const spareCostValue = spareCost.val();
        const laborCostValue = laborCost.val();
        const total = Number(spareCostValue) + Number(laborCostValue);
    
        if (isNaN(total)) {
            totalAmount.val('Valores inválidos');
        } else {
            totalAmount.val(total);
        }
    }

    $('.client_selector').select2({
        placeholder: 'Seleccione un cliente',
        minimumInputLength: 3,
        ajax: {
            url: route('admin.clients.all').toString(),
            dataType: 'json',
            delay: 300,
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            // TODO we should use helper (all selector will have the same function)
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.last_name && item.first_name
                            ? `${item.last_name}, ${item.first_name}`
                            : item.last_name
                    }
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    $('.product_selector').select2({
        placeholder: 'Seleccione un producto',
        minimumInputLength: 0,
        ajax: {
            url: (data) => {
                if (!data.term) {
                    return route('admin.products.all', { popular: true }).toString();
                }

                return route('admin.products.all').toString();
            },
            dataType: 'json',
            delay: 400, // bigger delay than the rest because minimumInputLength < 1
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    $('.brand_selector').select2({
        placeholder: 'Seleccione una marca',
        minimumInputLength: 0,
        ajax: {
            url: (data) => {
                if (!data.term) {
                    return route('admin.brands.all', { popular: true }).toString();
                }

                return route('admin.brands.all').toString();
            },
            dataType: 'json',
            delay: 400, // bigger delay than the rest because minimumInputLength < 1
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    $('.insurer_selector').select2({
        placeholder: 'Seleccione una compañía',
        minimumInputLength: 0,
        ajax: {
            url: route('admin.insurers.all').toString(),
            dataType: 'json',
            delay: 400,
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                // NOTE: This is to be able to remove a insurer. We can not use null values with select2 but Laravel convert empty strings to null
                results.unshift({
                    id: ' ',
                    text: 'Ninguna',
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    $('#multiselect').multiselect({
        sort: false
    });

    $('.summernote').summernote({
        height: 200,
        tabsize: 2,
        lang: 'es-ES',
        toolbar: [
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', []],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link', 'hr']],
            ['view', ['fullscreen', 'codeview']],
        ]
    });

    $('.summernote-small').summernote({
        height: 80,
        tabsize: 2,
        lang: 'es-ES',
        toolbar: [
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', []],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link', 'hr']],
            ['view', ['fullscreen', 'codeview']],
        ]
    });

    $('.datepicker').datepicker({
        todayHighlight: true,
        language: 'es',
        orientation: "bottom left",
        format: 'dd-mm-yyyy',
        startDate: new Date(),
        templates: {
            leftArrow: '<i class="la la-angle-left"></i>',
            rightArrow: '<i class="la la-angle-right"></i>'
        }
    });

    $('input[name="third_party_insurer"]').on('change', (e) => {
        const isSelected = $(e.target).prop('checked');

        if (isSelected) {
            $('.insurer_selector')
                .val(' ')
                .trigger('change')
                .trigger('select2:select')
                .prop('disabled', true);
            $('input[name="sinister_number"]').val('').prop('disabled', true);
        } else {
            $('.insurer_selector').prop('disabled', false);
            $('input[name="sinister_number"]').prop('disabled', false);
        }
    });

    // NOTE: We must update total when page is ready AND when numbers change
    updateTotal();
    $('input[name="spare_cost"], input[name="labor_cost"]').keyup(updateTotal);
});