'use strict';

import productsTable from '../../custom/products/table';

KTUtil.ready(() => {
    if (!$("#page-all-products").length) {
        return null;
    }

    productsTable.init();
});
