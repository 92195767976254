'use strict';

import photosModal from './modal-photos';

// Private functions and attributes
const inputSearch = $('#reports-table--search');
const selectFilterField = $('#reports-table--field-filter');
const selectInsurer = $('#reports-table--insurer-filter');
const selectReportStatus = $('#reports-table--status-filter');
const columnSwitcher = ('.column_switcher');

const init = () => {
    // We MUST get table element this way (we can't create global variable)
    const table = $('#reports-table');

    if (!table || !table.length) {
        return;
    }

    $([selectReportStatus, selectInsurer]).select2();
    const datatable = table.KTDatatable({
        // datasource definition
        data: {
            type: 'remote',
            source: {
                read: {
                    method: 'GET',
                    url: route('admin.reports.all').toString(),
                },
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            saveState: true,
        },

        // layout definition
        layout: {
            customScrollbar: false,
            scroll: true,
            // height: 400,
            footer: false,
            header: true,
            icons: {
                rowDetail: null
            }
        },

        // column sorting
        sortable: true,

        pagination: true,

        search: {
            onEnter: true,
            input: inputSearch,
        },

        // columns definition
        columns: [
            {
                field: 'id',
                title: '#',
                sortable: true,
                autoHide: false,
                textAlign: 'center',
                template: (row, index, datatable) => {
                    const dropup = (datatable.getPageSize() - index) <= 4 ? 'dropup' : '';
                    const options = [];
                    let html = '';

                    if (permissions.includes('UPDATE_REPORTS')) {
                        options.push({
                            id: row.id,
                            class: 'report_edit_btn',
                            icon: 'la la-edit',
                            text: 'Editar'
                        });
                    }

                    if (permissions.includes('VIEW_REPAIR_REPORT')) {
                        options.push({
                            id: row.id,
                            class: 'report_see_btn',
                            icon: 'la la-eye',
                            text: 'Ver presupuesto'
                        });
                    }

                    if (permissions.includes('VIEW_REPAIR_REPORT_PHOTOS')) {
                        options.push({
                            id: row.id,
                            class: 'report_photos_btn',
                            icon: 'la la-file-image',
                            text: 'Ver fotos'
                        });
                    }

                    if (permissions.includes('UPDATE_REPAIRS')) {
                        options.push({
                            divider: true,
                        });

                        options.push({
                            id: row.repair_id,
                            class: 'report_repair_btn',
                            icon: 'la la-external-link-alt',
                            text: 'Ver reparación'
                        });
                    }

                    if (!options.length) {
                        return row.id;
                    }

                    html = `
                        <div class="dropdown dropdown-inline ${dropup}">
                            <a href="#" class="btn btn-text-dark btn-hover-light font-weight-bold" data-toggle="dropdown">
                                <i class="flaticon-more-v2"></i>
                            </a>

                            <div class="dropdown-menu dropdown-menu-right actions">
                                ${
                                    options.map((option) => {
                                        if (option.divider) {
                                            return '<div class="dropdown-divider"></div>';
                                        }

                                        return `
                                            <button data-id="${option.id}" class="dropdown-item ${option.class}">
                                                <i class="${option.icon} mr-2"></i> ${option.text}
                                             </button>
                                        `;
                                    }).join('')
                                }
                            </div>
                        </div>

                        ${row.id}
                    `;

                    return html;
                }
            }, {
                field: 'repair_id',
                title: 'Reparación',
                sortable: true,
                autoHide: false,
                filterable: false
            }, {
                field: 'repair.client_id',
                title: 'Cliente',
                sortable: false,
                autoHide: false,
                filterable: false,
                template: (row) => {
                    const client = row.repair.client;

                    if (client.first_name && client.last_name) {
                        return `
                            <span
                                data-toggle="tooltip"
                                title="Nº de cliente: ${client.id}">
                                ${client.last_name} ${client.first_name}
                            <span/>
                        `;
                    }

                    return `
                        <span
                            data-toggle="tooltip"
                            title="Nº de cliente: ${client.id}">
                            ${client.last_name}
                        <span/>
                    `;
                }
            }, {
                field: 'repair.sinister_number',
                title: 'Número de siniestro',
                sortable: true,
                autoHide: false,
                filterable: false
            }, {
                field: 'repair.product_id',
                title: 'Producto',
                sortable: true,
                autoHide: false,
                filterable: false,
                template: (row) => {
                    if (row?.repair?.product?.description?.length) {
                        return `
                            <span
                                title="Descripción"
                                data-content="${row?.repair?.product?.description}"
                                data-trigger="hover"
                                data-toggle="popover">
                                ${row?.repair?.product?.name}
                            <span>
                        `;
                    }

                    return row?.repair?.product?.name;
                }
            }, {
                field: 'report_status_id',
                title: 'Estado actual',
                sortable: false,
                autoHide: false,
                filterable: false,
                width: 200,
                template: (row) => {
                    var status = row.status;

                    if (status) {
                        const classes = [
                            'label',
                            'label-light-primary',
                            'label-inline',
                            'font-weight-bold',
                        ];

                        return `
                            <span class="${classes.join(' ')}" style="color: #fff; height:auto; background: ${status.color}">
                                ${status.name}
                            </span>
                        `;
                    }
                }
            }, {
                field: 'repair.budget_at',
                title: 'Fecha de presupuesto',
                sortable: true,
                autoHide: false,
                filterable: false,
                type: 'datetime',
                template: (row) => {
                    return moment(row.repair.budget_at).format('DD-MM-YYYY');
                }
            }, {
                field: 'created_at',
                title: 'Fecha de creación',
                sortable: true,
                autoHide: false,
                filterable: false,
                type: 'datetime',
                template: (row) => {
                    return moment(row.created_at).format('DD-MM-YYYY');
                }
            }
        ]
    });

    /**
     * Table filter events
     */
    $(selectReportStatus).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'report_status_id');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectInsurer).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'insurer_id');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectFilterField).on('change', () => {
        datatable.search($(selectFilterField).val(), 'filter_by');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(columnSwitcher).change((e) => {
        const field = e.currentTarget.name;
        const active = e.currentTarget.checked;

        if (!datatable.getColumnByField(field)) {
            return;
        }

        datatable.columns(field).visible(active);
        datatable.stateKeep('columns', datatable.options.columns);
    });

    /**
     * Datatable events
     */
    datatable.on('datatable-on-init', () => {
        const tableMeta = datatable.stateGet('meta');
        const tableColumns = datatable.stateGet('columns');

        if (tableMeta && tableMeta.query && tableMeta.query.location_id) {
            $(selectLocation)
                .val(tableMeta.query.location_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.report_status_id) {
            $(selectReportStatus)
                .val(tableMeta.query.report_status_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.insurer_id) {
            $(selectInsurer)
                .val(tableMeta.query.insurer_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.filter_by) {
            $(selectFilterField).val(tableMeta.query.filter_by);
        }

        if (tableMeta && tableMeta.query && tableMeta.query.general_search) {
            $(inputSearch).val(tableMeta.query.general_search);
        }

        if (!tableColumns) {
            return null;
        }

        // Update table column visibility switchers
        $.each(tableColumns, function(index, obj) {
            const isVisible = obj.visible === undefined || obj.visible === true;

            datatable.columns(obj.field).visible(isVisible);
            datatable.stateKeep('columns', datatable.options.columns);
            $(`input[name="${obj.field}"]`).prop("checked", isVisible);
        });
    });

    datatable.on("datatable-on-layout-updated", () => {
        //NOTE: As we are adding content dynamically, we need to initialize all popover on every update
        $('[data-toggle="popover"]').popover();
        $('[data-toggle="tooltip"]').tooltip();
    });

    datatable.on('datatable-on-ajax-fail', (jqXHR) => {
        let text = 'No se pudo realizar la operación. Contacte con el equipo técnico';

        if (jqXHR && jqXHR.status) {
            text += `. Código de error: ${jqXHR.status}`
        }

        swal.fire({
            title: 'Oops... Algo salió mal 😢',
            text,
            icon: 'error'
        });
    });

    /**
     * Table action events
     */
    $(document.body).on('click', 'button.report_edit_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.reports.edit', {id: target.data('id')}).toString();

        window.open(url, '_self');
    });

    $(document.body).on('click', 'button.report_see_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.reports.get', {id: target.data('id')}).toString();

        window.open(url, '_blank')
    });

    $(document.body).on('click', 'button.report_photos_btn', (e) => {
        const target = $(e.currentTarget);
        const options = {
            id: target.data('id')
        };

        KTApp.block(table, {
            message: 'Cargando...'
        });

        photosModal.init(options)
            .then((response) => {
                KTApp.unblock(table);
            })
            .catch((err) => {
                KTApp.unblock(table);
            });
    });

    $(document.body).on('click', 'button.report_repair_btn', (e) => {
        const target = $(e.currentTarget);
        const url = route('admin.repairs.edit', {id: target.data('id')}).toString();

        window.open(url, '_self');
    });
};

const destroy = () => {
    // We MUST get table element this way (we can't create global variable)
    $('#reports-table').KTDatatable('destroy');
};

export default {
    init,
    destroy
}
