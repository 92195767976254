'use strict';

import authenticationLogsTable from '../../custom/auth/table';

KTUtil.ready(() => {
    if (!$("#page-all-authentication-logs").length) {
        return null;
    }

    authenticationLogsTable.init();
});
