'use strict';

import trackingStatusesTable from '../../custom/tracking-statuses/table';

KTUtil.ready(() => {
    if (!$("#page-all-tracking-statuses").length) {
        return null;
    }

    trackingStatusesTable.init();
});
