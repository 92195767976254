'use strict';

import clientsTable from './table';

const modal = $('#repairs-modal--client');

const init = (options) => {
    return new Promise((resolve) => {
        modal.modal();

        modal.on('hidden.bs.modal', (e) => {
            clientsTable.destroy();
            return resolve();
        });

        clientsTable.init(options, resolve);
    });
};

const close = () => {
    modal.modal('hide');
}

export default {
    init,
    close
};
