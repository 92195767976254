'use strict';

import previsualizationModal from '../../custom/reports/modal-previsualization';


KTUtil.ready(() => {
    if (!$("#page-edit-report").length) {
        return null;
    }

    const spareCost = $('input[name="repair[spare_cost]"]');
    const laborCost = $('input[name="repair[labor_cost]"]');
    const totalAmount = $('.total_amount');
    const emailList = $('[data-repeater-list]');
    const updateTotal = () => {
        const spareCostValue = spareCost.val();
        const laborCostValue = laborCost.val();
        const total = Number(spareCostValue) + Number(laborCostValue);
    
        if (isNaN(total)) {
            totalAmount.val('Valores inválidos');
        } else {
            totalAmount.val(total);
        }
    }
    const readURL = (input) => {
        if (!input || !input.files || !input.files.length) {
            $('#custom-image-uploader .dz-message').removeClass('d-none');
            return;
        }

        for (const file of input.files) {
            const reader = new FileReader();
            const container = document.createElement('div');
            container.className = 'dz-preview dz-processing dz-image-preview dz-error dz-complete';
            container.dataset.id = file.name;

            reader.onload = function(e) {
                const imgContainer = document.createElement('div');
                imgContainer.className = 'dz-image dz-custom';

                const img = document.createElement('img');
                img.src = e.target.result;
                img.alt = file.name;    

                imgContainer.append(img);
                container.append(imgContainer);

                $('#custom-image-uploader').append(container);
            };

            reader.readAsDataURL(file);
        }

        $('#custom-image-uploader .dz-message').addClass('d-none');
    };

    $('.report_status_selector').select2();

    $('[data-repeater-create]').on('click', () => {
        const clone = $('[data-repeater-item]').first().clone();
        clone.find('input').val('');

        $(emailList).append(clone);
    });

    $(document.body).on('click', '[data-repeater-delete]', (e) => {
        $(e.target).parents('[data-repeater-item]').remove();
    });

    $('#multiselect').multiselect({
        sort: false
    });

    $('.summernote').summernote({
        height: 200,
        tabsize: 2,
        lang: 'es-ES',
        toolbar: [
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', []],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link', 'hr']],
            ['view', ['fullscreen', 'codeview']],
        ]
    });

    $('.summernote-small').summernote({
        height: 80,
        tabsize: 2,
        lang: 'es-ES',
        toolbar: [
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', []],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link', 'hr']],
            ['view', ['fullscreen', 'codeview']],
        ]
    });

    $('.datepicker').datepicker({
        todayHighlight: true,
        language: 'es',
        orientation: "bottom left",
        format: 'dd-mm-yyyy',
        startDate: `01-01-${new Date().getFullYear() - 2}`,
        templates: {
            leftArrow: '<i class="la la-angle-left"></i>',
            rightArrow: '<i class="la la-angle-right"></i>'
        }
    });
    
    $('#custom-image-uploader').on('click', () => {
        $('#input-file').trigger('click');
    });

    // @TODO how to use arrow functions here?
    $('#input-file').on('change', function() {
        // NOTE: Removes all previous previews (the input refresh it self when the user clicks on 'cancel' or 'open')
        $('.dz-preview:not(.dz-image-saved)').remove();
        readURL(this);
    });

    $('.dz-image-saved img').on('click', (e) => {
        e.stopPropagation();
        
        const target = $(e.target);
        const wrapper = $(target).parents('.dz-image-saved');
        const id = $(wrapper).data('id');

        // Cancel deletion
        if ($(target).hasClass('to-delete')) {
            $('input#photo-' + id).remove();
            $(target).removeClass('to-delete');
            return null;
        }

        $(target).addClass('to-delete');

        $("<input>").attr({
            id: 'photo-' + id,
            name: 'report[delete_photos][]',
            type: 'hidden',
            value: id
        }).appendTo("form");

        return null;
    });

    // NOTE: We must update total and files when page is ready
    updateTotal();
    readURL($('#input-file')[0]);
    $('input[name="repair[spare_cost]"], input[name="repair[labor_cost]"]').keyup(updateTotal);

    // NOTE: For reports that have files, we populate the files in server side.. so we shoudn't show the default message
    if ($('#custom-image-uploader').find('.dz-preview').length) {
        $('#custom-image-uploader .dz-message').addClass('d-none');
    }

    $('input[name="report[send_report_email]"]').on('change', (e) => {
        if (e.currentTarget.checked) {
            $('input[name="report[send_report_owner]"]').prop('checked', true);
        }
    });

    $('input[name="report[send_report_owner]"]').on('click', (e) => {
        const isSendEmailChecked = $('input[name="report[send_report_email]"]').is(':checked');

        if (isSendEmailChecked) {
            e.preventDefault();
        }
    });

    $('button.previsualization').on('click', (e) => {
        e.preventDefault();

        const button = $(e.target);
        const wrapper = $('#page-edit-report');
        const form = $('#update-report-form');
        const reportId = $('input[name="id"]').val();

        KTApp.block(wrapper, {
            message: 'Cargando...'
        });

        button
            .toggleClass('px-9')
            .toggleClass('spinner spinner-white spinner-right');

        $.ajax({
            data: form.serialize(),
            url: route('admin.reports.previsualize', { id: reportId }).toString(),
            type: 'PUT',
            success: (response) => {
                const options = {
                    html: response
                };

                previsualizationModal.init(options)
                    .finally(() => {
                        KTApp.unblock(wrapper);

                        button
                            .toggleClass('px-9')
                            .toggleClass('spinner spinner-white spinner-right');
                    });
            },
            error: () => {
                KTApp.unblock(wrapper);

                button
                    .toggleClass('px-9')
                    .toggleClass('spinner spinner-white spinner-right');
            }
        });
    });
});