'use strict';

import usersTable from '../../custom/users/table';

KTUtil.ready(() => {
    if (!$("#page-all-users").length) {
        return null;
    }

    usersTable.init();
});
