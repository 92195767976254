'use strict';

const init = (options) => {
    return new Promise((resolve, reject) => {
        swal.fire({
            title: 'Espera un momento!',
            text: '¿Estás seguro de desactivar esta marca?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#5867dd',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (!result || !result.value) {
                return resolve();
            }

            $.ajax({
                data: null,
                url: route('admin.brands.delete', {id: options.id}).toString(),
                type: 'DELETE',
                success: (response) => {
                    return resolve(response);
                },
                error: (jqXHR) => {
                    return reject(jqXHR);
                }
            });
        });
    });
};

export default {
    init
};
