'use strict';

const init = (options) => {
    return new Promise((resolve, reject) => {
        const container = $('#modal-photos');

        // NOTE: We must delete images from previous reports
        container.empty();

        $.ajax({
            data: null,
            url: route('admin.reports.images', {id: options.id}).toString(),
            type: 'GET',
            success: (response) => {
                const images = [];

                for (const file of response) {
                    const aBtn = document.createElement('a');

                    $(aBtn).attr('data-lightbox', 'roadtrip');
                    $(aBtn).attr('href', file.url);
                    $(aBtn).attr('data-title', file.name);

                    images.push(aBtn);
                    container.append(aBtn);
                }
                
                $(images[0]).trigger('click');

                return resolve();
            },
            error: (jqXHR) => {
                return reject(jqXHR);
            }
        });
    });
};

export default {
    init
};
