'use strict';

import modalClient from '../../custom/clients/modal-client';

KTUtil.ready(() => {
    if (!$("#page-create-repair").length) {
        return null;
    }

    repairWizard.init();

    $('.location_selector').select2({
        minimumInputLength: 0,
        ajax: {
            url: route('admin.locations.all').toString(),
            dataType: 'json',
            delay: 400,
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            // @TODO we should use helper (all selector will have the same function)
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    $('.product_selector').select2({
        placeholder: 'Seleccione un producto',
        minimumInputLength: 0,
        ajax: {
            url: (data) => {
                if (!data.term) {
                    return route('admin.products.all', { popular: true }).toString();
                }

                return route('admin.products.all').toString();
            },
            dataType: 'json',
            delay: 400, // bigger delay than the rest because minimumInputLength < 1
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    $('.brand_selector').select2({
        placeholder: 'Seleccione una marca',
        minimumInputLength: 0,
        ajax: {
            url: (data) => {
                if (!data.term) {
                    return route('admin.brands.all', { popular: true }).toString();
                }

                return route('admin.brands.all').toString();
            },
            dataType: 'json',
            delay: 400, // bigger delay than the rest because minimumInputLength < 1
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    $('.insurer_selector').select2({
        placeholder: 'Seleccione una compañía',
        minimumInputLength: 0,
        ajax: {
            url: route('admin.insurers.all').toString(),
            dataType: 'json',
            delay: 400,
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                // NOTE: This is to be able to remove a insurer. We can not use null values with select2 but Laravel convert empty strings to null
                results.unshift({
                    id: ' ',
                    text: 'Ninguna',
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });

    $('.insurer_selector').on('select2:select', (e) => {
        const data = e?.params?.data;

        // NOTE: Empty string is "Ninguna"
        if (!data || data?.id === " ") {
            $('input[name="repair[sinister_number]"]').prop('disabled', true);
            $('input[name="alert[email_insurer]"]')
                .prop('disabled', true)
                .prop('checked', false);
        } else {
            $('input[name="repair[sinister_number]"]').prop('disabled', false);
            $('input[name="alert[email_insurer]"]').prop('disabled', false);
        }
    });

    $('.summernote').summernote({
        height: 200,
        tabsize: 2,
        lang: 'es-ES',
        toolbar: [
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', []],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link', 'hr']],
            ['view', ['fullscreen', 'codeview']],
        ]
    });

    $('#clients-search').on('keypress', (e) => {
        if (e.key === 'Enter' || e.which === 13) {
            e.stopPropagation();

            const options = {
                withSelector: true,
                query: {
                    general_search: $('#clients-search').val(),
                    filter_by: $('#clients-field-filter').val(),
                }
            };

            modalClient.init(options)
                .then((selected) => {
                    modalClient.close();

                    if (!selected) {
                        return false;
                    }

                    $('input[name="client[id]"]').attr('value', selected);
                    
                    $.ajax({
                        url: route('admin.clients.all').toString(),
                        data: {
                            'query[general_search]' : selected,
                            'query[filter_by]': 'id'
                        },
                        type: 'GET',
                        dataType: 'json',
                        success: async (response) => {
                            if (response && response.data && response.data[0]) {
                                populateClientForm(response.data[0]);
                                return null;
                            }

                            await swal.fire({
                                text: "Oops! No se pudo recuperar los datos del cliente.",
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: "Aceptar",
                                customClass: {
                                    confirmButton: "btn font-weight-bold btn-light"
                                }
                            });

                            location.reload();
                        },
                        error: async (xhr, status) => {
                            await swal.fire({
                                text: "Oops! No se pudo recuperar los datos del cliente.",
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: "Aceptar",
                                customClass: {
                                    confirmButton: "btn font-weight-bold btn-light"
                                }
                            });

                            location.reload();
                        },
                    });
                });

            // NOTE: This is necesary to prevent page reload when user press Enter
            return false;
        }
    });

    $('.datepicker').datepicker({
        todayHighlight: true,
        language: 'es',
        orientation: "bottom left",
        format: 'dd-mm-yyyy',
        startDate: new Date(),
        templates: {
            leftArrow: '<i class="la la-angle-left"></i>',
            rightArrow: '<i class="la la-angle-right"></i>'
        }
    });

    $('input[name="repair[third_party_insurer]"]').on('change', (e) => {
        const isSelected = $(e.target).prop('checked');

        if (isSelected) {
            $('.insurer_selector')
                .val(' ')
                .trigger('change')
                .trigger('select2:select')
                .prop('disabled', true);
            $('input[name="repair[sinister_number]"]').val('').prop('disabled', true);
        } else {
            $('.insurer_selector').prop('disabled', false);
            $('input[name="repair[sinister_number]"]').prop('disabled', false);
        }
    });
});

const populateClientForm = (data) => {
    $('#kt_wizard_repair_form').find('[name^="client"]').each((index, element) => {
        const input = $(element);
        const name = input
            .attr('name')
            .replace('client[', '')
            .replace(']', '');

        
        if (input.is('select')) {
            const newOption = new Option(data.location.name, data.location.id, true, true);

            // NOTE: This is the only way this works
            $('.location_selector option').first().remove(); // Remove previous selection
            $(input)
                .append(newOption)
                .val(data.location.id)
                .trigger('change');
        } else {
            input.val(data[name]);
        }
    });
};

const repairWizard = function () {
    // Base elements
    const _wizardEl = KTUtil.getById('kt_wizard_repair');
    const _formEl = KTUtil.getById('kt_wizard_repair_form');
    const _validations = [];

    // Private functions
    const initWizard = () => {
        // Initialize form wizard
        const _wizard = new KTWizard(_wizardEl, {
            startStep: 1, // initial active step number
            clickableSteps: true  // allow step clicking
        });

        // Validation before going to next page
        _wizard.on('beforeNext', async (wizard) => {
            // Don't go to the next step yet
            _wizard.stop();

            // Validate form
            const validator = _validations[wizard.getStep() - 1]; // get validator for currnt step

            if (!validator) {
                _wizard.start();
                return null;
            }

            try {
                const status = await validator.validate();

                if (status == 'Valid') {
                    _wizard.goNext();
                    KTUtil.scrollTop();
                    return null;
                }

                await swal.fire({
                    text: "Oops! parece que hay algunos errores.",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Aceptar",
                    customClass: {
                        confirmButton: "btn font-weight-bold btn-light"
                    }
                });

                KTUtil.scrollTop();
            } catch (err) {
                console.error("repair wizard :: beforeNext", err);
            }
        });

        // Change event
        _wizard.on('change', (wizard) => {
            KTUtil.scrollTop();

            if (wizard.isLastStep()) {
                $('#kt_wizard_repair_form').find('[name^="client"]').each((index, element) => {
                    const input = $(element);

                    if (input.is('select')) {
                        $(`div[data-name="${input.attr('name')}"]`).html(input.text() || '-');
                    } else {
                        $(`div[data-name="${input.attr('name')}"]`).html(input.val() || '-');
                    }
                });

                $('#kt_wizard_repair_form').find('[name^="repair"]').each((index, element) => {
                    const input = $(element);

                    if (input.is('select')) {
                        const data = $(input).select2('data')[0];
                        $(`div[data-name="${input.attr('name')}"]`).html(data?.text || '-');
                    } else if (input.is(':checkbox')) {
                        $(`div[data-name="${input.attr('name')}"]`).html(input.prop('checked') ? "Si" : 'No');
                    } else {
                        $(`div[data-name="${input.attr('name')}"]`).html(input.val() || '-');
                    }
                });

                $('#kt_wizard_repair_form').find('[name^="alert"]').each((index, element) => {
                    const input = $(element);

                    $(`div[data-name="${input.attr('name')}"]`).html(input.prop('checked') ? "Si" : 'No');
                });
            }            
        });
    }

    const initValidation = () => {
        // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
        // Step 1
        _validations.push(FormValidation.formValidation(
            _formEl,
            {
                fields: {
                    'client[dni]': {
                        validators: {
                            digits: {
                                message: 'Solo números, sin esapcios ni puntos.'
                            },
                            stringLength: {
                                min: 1,
                                max: 12,
                                message: 'Este campo debe tener entre 1 - 12 caracteres.'
                            }
                        }
                    },
                    'client[email]': {
                        validators: {
                            emailAddress: {
                                message: 'Este campo no es un correo válido.'
                            },
                            stringLength: {
                                min: 1,
                                max: 90,
                                message: 'Este campo debe tener entre 1 - 90 caracteres.'
                            }
                        }
                    },
                    'client[last_name]': {
                        validators: {
                            notEmpty: {
                                message: 'Este campo es requerido.'
                            },
                            stringLength: {
                                min: 1,
                                max: 45,
                                message: 'Este campo debe tener entre 1 - 45 caracteres.'
                            }
                        }
                    },
                    'client[first_name]': {
                        validators: {
                            stringLength: {
                                min: 1,
                                max: 45,
                                message: 'Este campo debe tener entre 1 - 45 caracteres.'
                            }
                        }
                    },
                    'client[phone]': {
                        validators: {
                            digits: {
                                message: 'Solo números, sin esapcios ni puntos.'
                            },
                            stringLength: {
                                min: 1,
                                max: 25,
                                message: 'Este campo debe tener entre 1 - 45 caracteres.'
                            }
                        }
                    },
                    'client[alternative_phone]': {
                        validators: {
                            digits: {
                                message: 'Solo números, sin esapcios ni puntos.'
                            },
                            stringLength: {
                                min: 1,
                                max: 25,
                                message: 'Este campo debe tener entre 1 - 45 caracteres.'
                            }
                        }
                    },
                    'client[location_id]': {
                        validators: {
                            notEmpty: {
                                message: 'Este campo es obligatorio.'
                            },
                            integer: {
                                message: 'Seleccione una localidad de la lista.'
                            }
                        }
                    },
                    'client[address]': {
                        validators: {
                            stringLength: {
                                min: 1,
                                max: 90,
                                message: 'Este campo debe tener entre 1 - 90 caracteres.'
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    bootstrap: new FormValidation.plugins.Bootstrap()
                }
            }
        ));

        // Step 2
        _validations.push(FormValidation.formValidation(
            _formEl,
            {
                fields: {
                    'repair[product_id]': {
                        validators: {
                            notEmpty: {
                                message: 'Este campo es requerido.'
                            },
                            integer: {
                                message: 'Seleccione un producto de la lista.'
                            }
                        }
                    },
                    'repair[brand_id]': {
                        validators: {
                            notEmpty: {
                                message: 'Este campo es requerido.'
                            },
                            integer: {
                                message: 'Seleccione una marca de la lista.'
                            }
                        }
                    },
                    'repair[model]': {
                        validators: {
                            notEmpty: {
                                message: 'Este campo es requerido.'
                            },
                            stringLength: {
                                min: 1,
                                max: 120,
                                message: 'Este campo debe tener entre 1 - 120 caracteres.'
                            }
                        }
                    },
                    'repair[serial_number]': {
                        validators: {
                            stringLength: {
                                min: 1,
                                max: 200,
                                message: 'Este campo debe tener entre 1 - 200 caracteres.'
                            }
                        }
                    },
                    'repair[partial_amount]': {
                        validators: {
                            stringLength: {
                                min: 1,
                                max: 8,
                                message: 'Este campo debe tener entre 1 - 8 caracteres.'
                            },
                            digits: {
                                message: 'Este campo debe tener sólo números del 0 al 9'
                            }
                        }
                    },
                    'repair[budget_at]': {
                        validators: {
                            notEmpty: {
                                message: 'Este campo es requerido.'
                            },
                            date: {
                                format: 'DD-MM-YYYY',
                                message: 'Este campo debe ser una fecha',
                            }
                        }
                    },
                    'repair[insurer_id]': {
                        validators: {
                            integer: {
                                message: 'Seleccione una compañía de la lista.'
                            }
                        }
                    },
                    'repair[sinister_number]': {
                        validators: {
                            notEmpty: {
                                message: 'Este campo es requerido.'
                            },
                            stringLength: {
                                min: 1,
                                max: 45,
                                message: 'Este campo debe tener entre 1 - 45 caracteres.'
                            }
                        }
                    },
                    'repair[inconvenient]': {
                        validators: {
                            stringLength: {
                                min: 1,
                                max: 1000,
                                message: 'Este campo debe tener entre 1 - 1000 caracteres.'
                            }
                        }
                    },
                    'repair[accessories]': {
                        validators: {
                            stringLength: {
                                min: 1,
                                max: 100,
                                message: 'Este campo debe tener entre 1 - 100 caracteres.'
                            }
                        }
                    },
                    'repair[technical_notes]': {
                        validators: {
                            stringLength: {
                                min: 1,
                                max: 1500,
                                message: 'Este campo debe tener entre 1 - 1500 caracteres.'
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    bootstrap: new FormValidation.plugins.Bootstrap(),
                    excluded: new FormValidation.plugins.Excluded({
                        excluded: function(field, ele, eles) {
                            if (field === 'repair[sinister_number]') {
                                const insurer = $('select[name="repair[insurer_id]"] option:selected').val();
                            
                                // NOTE: We need this` here because we use an empty string for 'non selection'
                                if (!insurer || insurer === ' ') {
                                    return true;
                                }
                            }

                            if (field === 'repair[insurer_id]') {
                                const sinisterNumber = $('input[name="repair[sinister_number]"]').val();
                            
                                if (!sinisterNumber && !sinisterNumber.trim().length) {
                                    return true;
                                }
                            }

                            return false;
                        },
                    }),
                }
            }
        ));
    }

    return {
        // public functions
        init: () => {
            initWizard();
            initValidation();
        }
    };
}();