'use strict';

KTUtil.ready(() => {
    const modal = $('#tasks-report-modal');
    const reportFrom = $('input[name="report_from"]');
    const reportTo = $('input[name="report_to"]')
    const filterBySelector = $('.tasks_report_selector');
    const statusesSelector = $('.tasks_report_status_selector');

    $('.task_report').on("click", () => {
        modal.modal();
    })
    
    modal.on('hidden.bs.modal', (e) => {
        reportFrom.val("");
        reportTo.val("");
        filterBy.val('budget_at');
    });

    filterBySelector.select2();

    statusesSelector.select2({
        placeholder: 'Seleccione al menos un estado',
        minimumInputLength: 0,
        multiple: true,
        ajax: {
            url: () => {
                return route('admin.tracking-statuses.all').toString();
            },
            dataType: 'json',
            delay: 400, // bigger delay than the rest because minimumInputLength < 1
            data: (params) => {
                return {
                    'query[general_search]': params.term, // search term
                    'pagination[page]': params.page || 1
                };
            },
            processResults: (response) => {
                if (!response || !response.data) {
                    return {
                        results: [],
                        more: false
                    };
                }

                const results = response.data.map((item) => {
                    return {
                        id: item.id,
                        text: item.name
                    }
                });

                return {
                    results,
                    pagination: {
                        more: response?.meta?.pages > response?.meta?.page
                    }
                };
            }   
        }
    });


    reportFrom.datepicker({
        todayHighlight: true,
        language: 'es',
        orientation: "bottom left",
        format: 'dd-mm-yyyy',
        defaultDate: new Date(),
        templates: {
            leftArrow: '<i class="la la-angle-left"></i>',
            rightArrow: '<i class="la la-angle-right"></i>'
        }
    });

    reportTo.datepicker({
        todayHighlight: true,
        language: 'es',
        orientation: "bottom left",
        format: 'dd-mm-yyyy',
        defaultDate: new Date(),
        templates: {
            leftArrow: '<i class="la la-angle-left"></i>',
            rightArrow: '<i class="la la-angle-right"></i>'
        }
    });
});
