'use strict';

import insurersTable from '../../custom/insurers/table';

KTUtil.ready(() => {
    if (!$("#page-all-insurers").length) {
        return null;
    }

    insurersTable.init();
});
