"use strict";

// default options
$.fn['KTDatatable'].defaults = {
	// datasource definition
	data: {
		type: 'local',
		source: null,
		pageSize: 40,
		saveState: true,

		serverPaging: false,
		serverFiltering: false,
		serverSorting: false,

		autoColumns: false,
		attr: {
			rowProps: [],
		},
	},

	// layout definition
	layout: {
		theme: 'default', // datatable will support multiple themes and designs
		class: 'datatable-primary', // custom wrapper class
		scroll: false, // enable/disable datatable scroll both horizontal and vertical when needed.
		height: null, // datatable's body's fixed height
		minHeight: null,
		footer: false, // display/hide footer
		header: true, // display/hide header
		customScrollbar: true, // set false to disable custom scrollbar

		// datatable spinner
		spinner: {
			overlayColor: '#000000',
			opacity: 0,
			type: 'loader',
			state: 'primary',
			message: true,
		},

		// datatable UI icons
		icons: {
			sort: {asc: 'flaticon2-arrow-up', desc: 'flaticon2-arrow-down'},
			pagination: {
				next: 'flaticon2-next',
				prev: 'flaticon2-back',
				first: 'flaticon2-fast-back',
				last: 'flaticon2-fast-next',
				more: 'flaticon-more-1',
			},
			rowDetail: {expand: 'fa fa-caret-down', collapse: 'fa fa-caret-right'},
		},
	},

	// column sorting
	sortable: true,

	// resize column size with mouse drag coming soon)
	resizable: false,

	// column based filtering (coming soon)
	filterable: false,

	pagination: true,

	// inline and bactch editing (cooming soon)
	editable: false,

	// columns definition
	columns: [],

	search: {
		// enable trigger search by keyup enter
		onEnter: false,
		// input text for search
		input: null,
		// search delay in milliseconds
		delay: 400,
		//	remote server search key value
		key: null
	},

	rows: {
		// deprecated
		callback: function() {
		},
		// call before row template
		beforeTemplate: function() {
		},
		// call after row template
		afterTemplate: function() {
		},
		autoHide: false, // TECMASTER
	},

	// toolbar
	toolbar: {
		// place pagination and displayInfo blocks according to the array order
		layout: ['pagination', 'info'],

		// toolbar placement can be at top or bottom or both top and bottom repeated
		placement: ['bottom'],  //'top', 'bottom'

		// toolbar items
		items: {
			// pagination
			pagination: {
				// pagination type(default or scroll)
				type: 'default',

				// number of pages to display by breakpoints
				pages: {
					desktop: {
						layout: 'default',
						pagesNumber: 5,
					},
					tablet: {
						layout: 'default',
						pagesNumber: 3,
					},
					mobile: {
						layout: 'compact',
					},
				},

				// navigation buttons
				navigation: {
					prev: true, // display prev button
					next: true, // display next button
					first: true, // display first button
					last: true, // display last button
					more: false // display more button
				},

				// page size select
				pageSizeSelect: [10, 20, 30, 40], // display dropdown to select pagination size. -1 is used for "ALl" option
			},

			// records info
			info: true,
		},
	},

	// here we will keep all strings and message used by datatable UI so developer can easiliy translate to any language.
	// By default the stirngs will be in the plugin source and here can override it
	// TECMASTER
	translate: {
		records: {
			processing: 'Cargando...',
			noRecords: 'Sin resultados',
		},
		toolbar: {
			pagination: {
				items: {
					default: {
						first: 'Primera',
						prev: 'Anterior',
						next: 'Siguiente',
						last: 'Última',
						more: 'Mág',
						input: 'Página número',
						select: 'Tamaño de página',
						all: 'todos',
					},
					info: 'Mostrando {{start}} - {{end}} de {{total}}',
				},
			},
		},
	},

	extensions: {},
};