'use strict';

import rolesTable from '../../custom/roles/table';

KTUtil.ready(() => {
    if (!$("#page-all-roles").length) {
        return null;
    }

    rolesTable.init();
});
