'use strict';

// Private functions and attributes
const inputSearch = $('#emails-table--search');
const selectFilterField = $('#emails-table--field-filter');
const selectInsurer = $('#emails-table--insurer-filter');
const selectEmailStatus = $('#emails-table--status-filter');
const columnSwitcher = ('.column_switcher');

const init = () => {
    // We MUST get table element this way (we can't create global variable)
    const table = $('#emails-table');

    if (!table || !table.length) {
        return;
    }

    $([selectEmailStatus, selectInsurer]).select2();
    const datatable = table.KTDatatable({
        // datasource definition
        data: {
            type: 'remote',
            source: {
                read: {
                    method: 'GET',
                    url: route('admin.emails.all').toString(),
                },
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            saveState: true,
        },

        // layout definition
        layout: {
            customScrollbar: false,
            scroll: true,
            // height: 400,
            footer: false,
            header: true,
            icons: {
                rowDetail: null
            }
        },

        // column sorting
        sortable: true,

        pagination: true,

        search: {
            onEnter: true,
            input: inputSearch,
        },

        // columns definition
        columns: [
            {
                field: 'id',
                title: '#',
                sortable: true,
                autoHide: false,
                textAlign: 'center'
            }, {
                field: 'repair_id',
                title: 'Reparación',
                sortable: true,
                autoHide: false,
                filterable: false
            }, {
                field: 'repair.client_id',
                title: 'Cliente',
                sortable: false,
                autoHide: false,
                filterable: false,
                template: (row) => {
                    const client = row.repair.client;

                    if (client.first_name && client.last_name) {
                        return `${client.first_name} ${client.last_name} (${client.id})`;
                    }

                    return `${client.last_name} (${client.id})`;
                }
            }, {
                field: 'subject',
                title: 'Asunto',
                sortable: true,
                autoHide: false,
                filterable: false,
            }, {
                field: 'created_at',
                title: 'Fecha de creación',
                sortable: true,
                autoHide: false,
                filterable: false,
                type: 'datetime',
                template: (row) => {
                    return moment(row.created_at).format('DD-MM-YYYY');
                }
            }
        ]
    });

    /**
     * Table filter events
     */
    $(selectEmailStatus).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'report_status_id');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectInsurer).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'insurer_id');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(selectFilterField).on('change', () => {
        datatable.search($(selectFilterField).val(), 'filter_by');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(columnSwitcher).change((e) => {
        const field = e.currentTarget.name;
        const active = e.currentTarget.checked;

        if (!datatable.getColumnByField(field)) {
            return;
        }

        datatable.columns(field).visible(active);
        datatable.stateKeep('columns', datatable.options.columns);
    });

    /**
     * Datatable events
     */
    datatable.on('datatable-on-init', () => {
        const tableMeta = datatable.stateGet('meta');
        const tableColumns = datatable.stateGet('columns');

        if (tableMeta && tableMeta.query && tableMeta.query.location_id) {
            $(selectLocation)
                .val(tableMeta.query.location_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.report_status_id) {
            $(selectReportStatus)
                .val(tableMeta.query.report_status_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.insurer_id) {
            $(selectInsurer)
                .val(tableMeta.query.insurer_id)
                .trigger('change');
        }

        if (tableMeta && tableMeta.query && tableMeta.query.filter_by) {
            $(selectFilterField).val(tableMeta.query.filter_by);
        }

        if (tableMeta && tableMeta.query && tableMeta.query.general_search) {
            $(inputSearch).val(tableMeta.query.general_search);
        }

        if (!tableColumns) {
            return null;
        }

        // Update table column visibility switchers
        $.each(tableColumns, function(index, obj) {
            const isVisible = obj.visible === undefined || obj.visible === true;

            datatable.columns(obj.field).visible(isVisible);
            datatable.stateKeep('columns', datatable.options.columns);
            $(`input[name="${obj.field}"]`).prop("checked", isVisible);
        });
    });

    datatable.on('datatable-on-ajax-fail', (jqXHR) => {
        let text = 'No se pudo realizar la operación. Contacte con el equipo técnico';

        if (jqXHR && jqXHR.status) {
            text += `. Código de error: ${jqXHR.status}`
        }

        swal.fire({
            title: 'Oops... Algo salió mal 😢',
            text,
            icon: 'error'
        });
    });

    /**
     * Table action events
     */
};

const destroy = () => {
    // We MUST get table element this way (we can't create global variable)
    $('#emails-table').KTDatatable('destroy');
};

export default {
    init,
    destroy
}
