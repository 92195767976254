'use strict';

import { console } from "es6-shim/es6-shim.min";

// Private functions and attributes
const inputSearch = $('#authentication-logs-table--search');
const selectFilterField = $('#authentication-logs-table--field-filter');
const columnSwitcher = ('.column_switcher');

const init = () => {
    // We MUST get table element this way (we can't create global variable)
    const table = $('#authentication-logs-table');

    if (!table || !table.length) {
        return;
    }

    const datatable = table.KTDatatable({
        // datasource definition
        data: {
            type: 'remote',
            source: {
                read: {
                    method: 'GET',
                    url: route('admin.auth.all').toString(),
                },
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            saveState: true,
        },

        // layout definition
        layout: {
            customScrollbar: false,
            scroll: true,
            // height: 400,
            footer: false,
            header: true,
            icons: {
                rowDetail: null
            }
        },

        // column sorting
        sortable: true,

        pagination: true,

        search: {
            onEnter: true,
            input: inputSearch,
        },

        // columns definition
        columns: [
            {
                field: 'id',
                title: '#',
                sortable: true,
                autoHide: false,
                textAlign: 'center',
            }, {
                field: 'user_id',
                title: 'Usuario',
                sortable: true,
                autoHide: false,
                template: (row) => {
                    return `${row?.user?.last_name} ${row?.user?.first_name}`
                }
            }, {
                field: 'ip_address',
                title: 'IP',
                sortable: true,
                autoHide: false,
            }, {
                field: 'login_at',
                title: 'Fecha',
                sortable: true,
                autoHide: false,
                filterable: false,
                type: 'datetime',
                template: (row) => {
                    return moment(row.login_at).format('DD-MM-YYYY');
                }
            }
        ],
    });

    /**
     * Table filter events
     */
    $(selectFilterField).on('change', (e) => {
        datatable.search(e.currentTarget.value, 'filter_by');
        datatable.setDataSourceParam('pagination', {
            page: 1,
            perpage: datatable.getDataSourceParam('pagination.perpage')
        });
    });

    $(columnSwitcher).change((e) => {
        const field = e.currentTarget.name;
        const active = e.currentTarget.checked;

        if (!datatable.getColumnByField(field)) {
            return;
        }

        datatable.columns(field).visible(active);
        datatable.stateKeep('columns', datatable.options.columns);
    });

    /**
     * Datatable events
     */
    datatable.on('datatable-on-init', () => {
        const tableMeta = datatable.stateGet('meta');
        const tableColumns = datatable.stateGet('columns');

        if (tableMeta && tableMeta.query && tableMeta.query.filter_by) {
            $(selectFilterField).val(tableMeta.query.filter_by);
        }

        if (tableMeta && tableMeta.query && tableMeta.query.general_search) {
            $(inputSearch).val(tableMeta.query.general_search);
        }

        if (!tableColumns) {
            return null;
        }

        // Update table column visibility switchers
        $.each(tableColumns, function(index, obj) {
            const isVisible = obj.visible === undefined || obj.visible === true;

            datatable.columns(obj.field).visible(isVisible);
            datatable.stateKeep('columns', datatable.options.columns);
            $(`input[name="${obj.field}"]`).prop("checked", isVisible);
        });
    });

    datatable.on('datatable-on-ajax-fail', (jqXHR) => {
        let text = 'No se pudo realizar la operación. Contacte con el equipo técnico';

        if (jqXHR && jqXHR.status) {
            text += `. Código de error: ${jqXHR.status}`
        }

        swal.fire({
            title: 'Oops... Algo salió mal 😢',
            text,
            icon: 'error'
        });
    });
};

const destroy = () => {
    // We MUST get table element this way (we can't create global variable)
    $('#authentication-logs-table').KTDatatable('destroy');
};

export default {
    init,
    destroy
}
