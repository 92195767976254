'use strict';

const modal = $('#reports-modal--previsualization');

const init = (options) => {
    return new Promise((resolve) => {
        modal.modal();

        modal.on('hidden.bs.modal', (e) => {
            return resolve();
        });

        $(modal).find('.modal-body').html(options.html)
    });
};

const close = () => {
    modal.modal('hide');
}

export default {
    init,
    close
};
